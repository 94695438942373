<script setup>
import axiosInstance from "@/lib/axios";
import { formatAmount } from "@/lib/utils";
import { useSnackbarStore } from "@/store";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { useField, useForm } from "vee-validate";
import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";
import * as yup from "yup";

import emblem from "@/assets/logo-emblem.svg";
import logoname from "@/assets/logo-name.svg";

const route = useRoute();
const snackbarStore = useSnackbarStore();

const { mobile } = useDisplay();

const paymentLinkId = route.params.paymentLinkId;
const isValidLink = ref(true);
const isRedirecting = ref(false);

async function getPaymentLinkInfo() {
  try {
    return (await axiosInstance.get(`/payment_links/${paymentLinkId}/info`))
      .data;
  } catch (error) {
    isValidLink.value = false;
    if (error.response && error.response.status === 400) {
      console.log(error);
      snackbarStore.showSnackbar(
        "No pudimos obtener la información de tu link de pago, vuelve a intentarlo"
      );
    } else {
      throw error;
    }
  }
}

const {
  isPending,
  isError,
  data: paymentLinkData,
  refetch,
  error,
} = useQuery({
  queryKey: ["getPaymentLinkInfo"],
  queryFn: getPaymentLinkInfo,
  disabled: true,
});

const validationSchema = yup.object().shape({
  fullname: yup.string().required("El campo es requerido"),
  documentType: yup.string().required("El campo es requerido"),
  documentNumber: yup
    .number()
    .typeError("Debe ser un número")
    .required("El campo es requerido"),
  phoneNumber: yup
    .number()
    .typeError("Debe ser un número")
    .required("El campo es requerido"),
  email: yup
    .string()
    .email("Debes ingresar un email valido")
    .matches(/^(?!.*@[^,]*,)/)
    .required("El campo es requerido"),
  bank: yup.string().required("El campo es requerido"),
});

const { handleSubmit } = useForm({
  validationSchema,
});

const { value: documentTypeValue, errorMessage: documentTypeErrorMessage } =
  useField("documentType", validationSchema);

const { value: documentNumberValue, errorMessage: documentNumberErrorMessage } =
  useField("documentNumber", validationSchema);

const { value: fullnameValue, errorMessage: fullnameErrorMessage } = useField(
  "fullname",
  validationSchema
);

const { value: phoneNumberValue, errorMessage: phoneNumberErrorMessage } =
  useField("phoneNumber", validationSchema);

const { value: emailValue, errorMessage: emailErrorMessage } = useField(
  "email",
  validationSchema
);

const { value: bankValue, errorMessage: bankErrorMessage } = useField(
  "bank",
  validationSchema
);

const { isPending: isPendingPse, mutate: mutatePse } = useMutation({
  mutationFn: () =>
    axiosInstance.post(
      `/payment_links/payin`,
      {
        payment_link_id: paymentLinkId,
        payin_request: {
          method_type: "PSE",
          details: {
            payer_type:
              documentTypeValue.value == "CC" ? "INDIVIDUAL" : "BUSINESS",
            payer_fullname: fullnameValue.value,
            payer_doc_number: documentNumberValue.value,
            payer_doc_type: documentTypeValue.value,
            payer_phone_number: documentNumberValue.value,
            bank_code: bankValue.value,
          },
        },
      },
      { timeout: 30000 }
    ),
  onSuccess: (response) => {
    isRedirecting.value = true;
    window.location.replace(response.data.payment_url);
  },
  onError: (error) => {
    if (error.response && error.response.status === 400) {
      switch (error.response.data.code) {
        case "pending_trx_for_payment_link":
          snackbarStore.showSnackbar(
            "Tienes otra transacción de PSE pendiente por confirmar"
          );
          break;
        default:
          snackbarStore.showSnackbar(
            "No pudimos reenviarte a PSE, intenta de nuevo"
          );
      }
    } else {
      snackbarStore.showSnackbar(
        "No pudimos reenviarte a PSE, intenta de nuevo"
      );
    }
  },
});

const onSubmit = handleSubmit((values) => {
  mutatePse(values);
});

onBeforeMount(async () => {
  await refetch();
});

const documentTypeList = [
  {
    name: "Cédula",
    code: "CC",
  },
  {
    name: "Cedúla Extranjeria",
    code: "CE",
  },
];

const bankList = [
  {
    name: "AV Villas",
    code: "CO_AV_VILLAS",
  },
  {
    name: "Bancolombia",
    code: "CO_BANCOLOMBIA",
  },
  {
    name: "Banco de Bogotá",
    code: "CO_BOGOTA",
  },
  {
    name: "BBVA",
    code: "CO_BBVA",
  },
  {
    name: "Banco de Bogotá",
    code: "CO_BOGOTA",
  },
  {
    name: "Banco de Occidente",
    code: "CO_OCCIDENTE",
  },
  {
    name: "Banco Popular",
    code: "CO_POPULAR",
  },
  {
    name: "Daviplata",
    code: "CO_DAVIPLATA",
  },
  {
    name: "Lulo Bank",
    code: "CO_LULO",
  },
  {
    name: "Davivienda",
    code: "CO_DAVIVIEDA",
  },
  {
    name: "Movii",
    code: "CO_MOVII",
  },
  {
    name: "Nequi",
    code: "CO_NEQUI",
  },
  {
    name: "Nu",
    code: "CO_NU",
  },
  {
    name: "RappiPay",
    code: "CO_RAPPIPAY",
  },
  {
    name: "ScotiaBank",
    code: "CO_SCOTIABANK",
  },
];
</script>

<template>
  <v-container class="d-flex flex-column justify-center align-center" style="">
    <v-container class="d-flex justify-center align-center mb-0">
      <v-sheet width="70">
        <v-img :src="emblem"></v-img>
      </v-sheet>
      <v-sheet width="100">
        <v-img :src="logoname"></v-img>
      </v-sheet>
    </v-container>
    <v-card
      class="pa-5 mx-5"
      variant="elevated"
      :loading="isPendingPse || isPending || isRedirecting"
      :style="mobile ? 'width: 90vw' : 'width: 60vw'"
    >
      <v-card-text v-if="!paymentLinkId || !isValidLink" class="pa-4">
        <p class="text-center">No pudimos encontrar tu link de pago</p>
      </v-card-text>

      <v-card-text
        v-if="
          paymentLinkId &&
          isValidLink &&
          paymentLinkData &&
          paymentLinkData.status != 'PENDING'
        "
        class="pa-4"
      >
        <p class="text-center">Este link de pago no puede ser utilizado</p>
      </v-card-text>

      <v-card-text
        v-if="
          paymentLinkId &&
          isValidLink &&
          paymentLinkData &&
          paymentLinkData.status == 'PENDING'
        "
        class="px-4"
      >
        <v-row class="mb-5">
          <v-col class="text-center">
            <span class="font-weight-bold text-body-1">Comercio</span>
            <p>{{ paymentLinkData.requester.name }}</p>
          </v-col>

          <v-col class="text-center">
            <span class="font-weight-bold text-body-1">Monto</span>
            <p>{{ formatAmount(paymentLinkData.amount) }}</p>
          </v-col>

          <v-col class="text-center">
            <span class="font-weight-bold text-body-1">Referencia</span>
            <p>{{ paymentLinkData.reference }}</p>
          </v-col>
        </v-row>
        <Form @submit.prevent="onSubmit">
          <!-- <v-row>
            <v-col class="text-end">
              <v-row
                class="text-body-1 font-weight-bold"
                align="center"
                no-gutters
              >
                <v-col class="mr-5">Pago por</v-col>
                <v-col
                  ><v-img
                    class="button-image"
                    aspect-ratio="16/9"
                    cover
                    src="https://seeklogo.com/images/P/pse-logo-B9F0EF77DD-seeklogo.com.png"
                  ></v-img
                ></v-col>
              </v-row>
            </v-col>
          </v-row> -->
          <v-row>
            <v-text-field
              variant="outlined"
              clearable
              label="Nombre completo"
              maxlength="100"
              v-model="fullnameValue"
              :error-messages="fullnameErrorMessage"
              :disabled="isPendingPse"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-select
              :items="documentTypeList"
              item-title="name"
              item-value="code"
              label="Tipo de documento"
              variant="outlined"
              v-model="documentTypeValue"
              :error-messages="documentTypeErrorMessage"
              :disabled="isPendingPse"
            >
              <template v-slot:item="{ props }">
                <v-list-item v-bind="props"></v-list-item>
              </template>
            </v-select>
          </v-row>
          <v-row>
            <v-text-field
              variant="outlined"
              clearable
              label="Número de documento"
              maxlength="10"
              v-model="documentNumberValue"
              :error-messages="documentNumberErrorMessage"
              :disabled="isPendingPse"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              variant="outlined"
              clearable
              label="E-mail"
              v-model="emailValue"
              :error-messages="emailErrorMessage"
              :disabled="isPendingPse"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              variant="outlined"
              clearable
              label="Número de teléfono"
              maxlength="10"
              v-model="phoneNumberValue"
              :error-messages="phoneNumberErrorMessage"
              :disabled="isPendingPse"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-select
              :items="bankList"
              item-title="name"
              item-value="code"
              label="Banco"
              variant="outlined"
              v-model="bankValue"
              :error-messages="bankErrorMessage"
              :disabled="isPendingPse"
            >
              <template v-slot:item="{ props }">
                <v-list-item v-bind="props"></v-list-item>
              </template>
            </v-select>
          </v-row>
          <v-row>
            <v-btn
              class="text-none"
              color="primary"
              variant="elevated"
              @click="onSubmit"
              :disabled="isPendingPse"
              block
            >
              Continuar a PSE
            </v-btn>
          </v-row>
        </Form>
      </v-card-text>
    </v-card>
  </v-container>

  <v-dialog v-model="isRedirecting" max-width="400" persistent>
    <v-card :loading="isRedirecting">
      <template v-slot:prepend
        ><v-icon icon="mdi-information" color="primary"></v-icon>
      </template>

      <v-card-text>
        <p class="text-none text-center mb-5">
          Te estamos redireccionando a PSE
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
