<script setup>
import InitLoading from "@/components/InitLoading.vue";
import PaymentMethodCard from "@/components/PaymentMethodCard.vue";
import axiosInstance from "@/lib/axios";
import { useQuery } from "@tanstack/vue-query";
import { onMounted } from "vue";

async function getPaymentMethods() {
  try {
    return (await axiosInstance.get("/payment_methods")).data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.log(error);
    } else {
      throw error;
    }
  }
}

const {
  isPending,
  isError,
  data: paymentMethodsData,
  error,
  refetch,
} = useQuery({
  queryKey: ["getPaymentMethods"],
  queryFn: getPaymentMethods,
});

onMounted(async () => {
  // skip
});
</script>

<template>
  <div>
    <v-container>
      <v-row class="justify-center">
        <v-col>
          <v-btn
            class="text-none"
            color="primary"
            variant="outlined"
            :to="{ name: 'paymentmethods-create' }"
            block
          >
            Registrar método de pago
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <p class="text-h6 text-center font-weight-bold">Métodos de pago</p>
    </v-container>

    <v-container
      v-if="paymentMethodsData && paymentMethodsData.length == 0"
      style="height: 200px"
    >
      <v-row align-content="center" class="fill-height" justify="center">
        <v-col class="text-body-12 text-center" cols="12">
          No tienes ningun método de pago registrado
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="paymentMethodsData" fluid>
      <v-row
        v-for="paymentMethod in paymentMethodsData"
        :key="paymentMethod.id"
      >
        <PaymentMethodCard
          :id="paymentMethod.id"
          :accountNumber="paymentMethod.nequi_token?.phone_number"
          :methodType="paymentMethod.method_type"
          methodImgUrl="https://cdn.prod.website-files.com/6317a229ebf7723658463b4b/652fd8b75306a15707b8a415_32x32%20N.png"
          @refresh-payment-method-list="refetch"
        />
      </v-row>
    </v-container>

    <v-container v-else-if="isError" style="height: 400px">
      {{ error }}
    </v-container>

    <InitLoading :show="isPending" />
  </div>
</template>
