import { defineStore } from "pinia";

export const useAlertsStore = defineStore("alerts", {
  state: () => ({
    confirmContractRemoval: { show: false, contractId: "" },
  }),
  getters: {},
  actions: {
    requestContractRemovalConfirmation(contractId: string) {
      this.confirmContractRemoval.show = true;
      this.confirmContractRemoval.contractId = contractId;
    },
  },
});

export const useLoaderStore = defineStore("loader", {
  state: () => ({
    show: false,
    message: "Un momento por favor...",
  }),
  getters: {},
  actions: {
    showLoader(message: string) {
      this.show = true;
      this.message = message ?? this.message;
    },
    hideLoader() {
      this.show = false;
    },
  },
});

export const useSnackbarStore = defineStore("snackbar", {
  state: () => ({
    show: false,
    message: "",
  }),
  getters: {},
  actions: {
    showSnackbar(message: string) {
      this.show = true;
      this.message = message ?? this.message;
    },
    hideSnackbar() {
      this.show = false;
    },
  },
});

export type CartItem = {
  id: string;
  amount: number;
  contractNumber: string;
  billerName: string;
};

export const useCartStore = defineStore("cart", {
  persist: true,
  state: () => ({
    cartItems: [] as CartItem[],
  }),
  getters: {
    isItemInCart: (state) => {
      return (itemId: string) =>
        state.cartItems.find((item) => item.id === itemId) ? true : false;
    },
    cartCount: (state) => state.cartItems.length,
    cartTotal: (state) => {
      const result = state.cartItems.reduce(
        (prevItem, item) => prevItem + item.amount,
        0
      );
      console.log(result);

      return result;
    },
    cartItemIds: (state) => state.cartItems.map((item) => item.id),
  },
  actions: {
    addItem(cartItem: CartItem) {
      this.cartItems.push(cartItem);
    },
    removeItem(itemId: string) {
      const index = this.cartItems.findIndex((obj) => obj.id === itemId);
      if (index !== -1) {
        this.cartItems.splice(index, 1);
      }
    },
    clear() {
      this.cartItems = [];
    },
  },
});

export type PaymentDetails = { paymentMethodId?: string };

export const usePaymentDetailsStore = defineStore("paymentDetails", {
  state: () => ({
    details: {} as PaymentDetails,
  }),
  getters: {},
  actions: {
    setPaymentMethodId(id: string) {
      this.details.paymentMethodId = id;
    },
    clear() {
      this.details = {};
    },
  },
});
