<script setup>
import axiosInstance from "@/lib/axios";
import { useSnackbarStore } from "@/store";
import { useMutation } from "@tanstack/vue-query";
import { useField, useForm } from "vee-validate";
import { ref } from "vue";
import * as yup from "yup";

const snackbarStore = useSnackbarStore();
const step = ref(1);

const validationSchema = yup.object().shape({
  paymentMethodTypeChoice: yup.string().required(),
  phoneNumber: yup
    .string()
    .nullable()
    .optional()
    .when("paymentMethodTypeChoice", {
      is: "nequi",
      then: () =>
        yup
          .number()
          .typeError("Debe ser un número")
          .required("El campo es requerido"),
    }),
});

const { handleSubmit } = useForm({
  validationSchema,
});
const phoneNumber = useField("phoneNumber", validationSchema);
const paymentMethodTypeChoice = useField(
  "paymentMethodTypeChoice",
  validationSchema
);

const { isPending, mutate } = useMutation({
  mutationFn: ({ phoneNumber }) =>
    axiosInstance.post("/payments/nequi/registration", {
      phone_number: phoneNumber,
    }),
  onSuccess: () => {
    step.value = 2;
  },
  onError: (error) => {
    if (error.response && error.response.status === 400) {
      switch (error.response.data.code) {
        case "nequi_token_exists_for_user":
          snackbarStore.showSnackbar(
            "Ya hay un método de pago para este número de Nequi"
          );
          break;
        default:
          snackbarStore.showSnackbar(
            "No pudimos solicitar el registro de tu método de pago. Verifica el número y vuelve a intentar"
          );
      }
    } else {
      snackbarStore.showSnackbar(
        "No pudimos solicitar el registro de tu método de pago. Verifica el número y vuelve a intentar"
      );
    }
  },
});

const onSubmit = handleSubmit((values) => {
  if (paymentMethodTypeChoice.value.value == "nequi") {
    mutate(values);
  } else {
    snackbarStore.showSnackbar("El método de pago aún no esta disponible");
  }
});
</script>

<template>
  <v-container>
    <p class="text-h6 font-weight-bold text-center">Nuevo método de pago</p>
    <v-card
      class="pa-5 my-5"
      variant="elevated"
      :loading="isPending"
      :hidden="step != 1"
    >
      <v-card-text>
        <p class="text-body-1 mb-5 text-center">
          Escoja el tipo de método de pago a asociar:
        </p>
        <v-form @submit.prevent="onSubmit">
          <v-row class="mt-5 flex-center">
            <v-radio-group v-model="paymentMethodTypeChoice.value.value" inline>
              <v-radio color="#5e63b6" value="nequi">
                <template v-slot:label>
                  <span class="text-body-2">Nequi</span>
                </template>
              </v-radio>
              <v-radio color="#5e63b6" value="bancolombia">
                <template v-slot:label>
                  <span class="text-body-2">Bancolombia</span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-row>
          <v-row class="mb-1">
            <v-text-field
              v-if="paymentMethodTypeChoice.value.value == 'nequi'"
              variant="outlined"
              clearable
              label="Número de teléfono"
              maxlength="10"
              v-model="phoneNumber.value.value"
              :error-messages="phoneNumber.errorMessage.value"
              :disabled="isPending"
            ></v-text-field>
          </v-row>
          <input
            type="hidden"
            name="paymentMethodTypeChoice"
            class="form-control"
            :value="paymentMethodTypeChoice.value.value"
          />
        </v-form>
        <v-row class="text-center">
          <v-col>
            <v-btn
              class="text-none"
              color="primary"
              variant="outlined"
              :disabled="isPending"
              :to="{ name: 'paymentmethods-list' }"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              class="text-none"
              variant="outlined"
              color="primary"
              @click="onSubmit"
              :disabled="isPending"
              >Continuar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      class="pa-5 my-5"
      variant="elevated"
      :loading="isPending"
      :hidden="step != 2"
    >
      <v-card-text>
        <p class="text-body-1 mb-5 text-center font-weight-bold">
          Suscripción Nequi enviada
        </p>
        <p class="my-5 text-justify" style="line-height: 2">
          Ve a tu aplicación Nequi y acepta la suscripción de Cayenatech,
          segundos después podras ver el nuevo método de pago reflejado en
          AloPay.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" :to="{ name: 'paymentmethods-list' }">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
