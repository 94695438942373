<script setup>
import axiosInstance from "@/lib/axios";
import { formatAmount } from "@/lib/utils";
import { useSnackbarStore } from "@/store";
import { useQuery } from "@tanstack/vue-query";
import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";

import emblem from "@/assets/logo-emblem.svg";
import logoname from "@/assets/logo-name.svg";

const { mobile } = useDisplay();
const route = useRoute();

const snackbarStore = useSnackbarStore();

const paymentLinkId = route.params.paymentLinkId;
const isValidLink = ref(true);

async function getPaymentLinkInfo() {
  try {
    return (await axiosInstance.get(`/payment_links/${paymentLinkId}/info`))
      .data;
  } catch (error) {
    isValidLink.value = false;
    if (error.response && error.response.status === 400) {
      console.log(error);
      snackbarStore.showSnackbar(
        "No pudimos obtener la información de tu link de pago, vuelve a intentarlo"
      );
    } else {
      throw error;
    }
  }
}

const {
  isPending,
  isError,
  data: paymentLinkData,
  refetch,
  error,
} = useQuery({
  queryKey: ["getPaymentLinkInfo"],
  queryFn: getPaymentLinkInfo,
  disabled: true,
});

onBeforeMount(async () => {
  await refetch();
});
</script>

<template>
  <v-container
    class="d-flex flex-column justify-center align-center"
    style="height: 100vh"
  >
    <v-container class="d-flex justify-center align-center mb-0">
      <v-sheet width="70">
        <v-img :src="emblem"></v-img>
      </v-sheet>
      <v-sheet width="100">
        <v-img :src="logoname"></v-img>
      </v-sheet>
    </v-container>
    <v-card
      class="pa-5 mx-5"
      variant="elevated"
      :loading="isPending"
      :style="mobile ? 'width: 90vw' : 'width: 60vw'"
    >
      <v-card-text
        class="pa-4"
        v-if="paymentLinkId && isValidLink && paymentLinkData"
      >
        <v-row>
          <v-col class="text-center">
            <span class="font-weight-bold text-body-1">Cobro por</span>
            <p>{{ paymentLinkData.requester.name }}</p>
          </v-col>

          <v-col class="text-center">
            <span class="font-weight-bold text-body-1">Referencia</span>
            <p>{{ paymentLinkData.reference }}</p>
          </v-col>

          <v-col class="text-center">
            <span class="font-weight-bold text-body-1">Monto</span>
            <p>{{ formatAmount(paymentLinkData.amount) }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-alert
            text="Estamos confirmado tu pago. En cuanto recibamos esta confirmación enviaremos una notificación a tu correo y notificaremos al comercio."
            type="info"
            color="deep-purple-accent-4"
            variant="tonal"
            class="my-5"
          ></v-alert>
        </v-row>
        <v-row>
          <v-btn
            class="text-none"
            color="primary"
            variant="elevated"
            :href="paymentLinkData.redirect_url"
            block
          >
            Volver al comercio
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
