<script setup>
import axiosInstance from "@/lib/axios";
import { useSnackbarStore } from "@/store";
import { useMutation } from "@tanstack/vue-query";
import { vMaska } from "maska/vue";
import { useField, useForm } from "vee-validate";
import { defineExpose, ref } from "vue";
import * as yup from "yup";

const snackbarStore = useSnackbarStore();

const newLinkUri = ref("");
const amountUnmaskedValue = ref("");
const isShowLinkResult = ref(false);
defineExpose({ amountUnmaskedValue });

const validationSchema = yup.object().shape({
  amount: yup.string().required("El campo es requerido"),
  reference: yup.string().max(100).required("El campo es requerido"),
});

const { handleSubmit } = useForm({
  validationSchema,
});
const amount = useField("amount", validationSchema);
const reference = useField("reference", validationSchema);

const { isPending, mutate } = useMutation({
  mutationFn: async ({ reference }) =>
    await axiosInstance.post("/payment_links", {
      amount: amountUnmaskedValue.value,
      reference: reference,
      redirect_url: "https://alopay.cayenatech.com",
    }),
  onSuccess: (response) => {
    newLinkUri.value = response.data.url;
    isShowLinkResult.value = true;
  },
  onError: (error) => {
    snackbarStore.showSnackbar(
      "No pudimos crear el link, por favor vuelve a intentar"
    );
  },
});

const onSubmit = handleSubmit((values) => {
  mutate(values);
});

const options = {
  onMaska: (detail) => (amountUnmaskedValue.value = detail.unmasked),
  mask: "9.99#",
  tokens: {
    9: { pattern: /[0-9]/, repeated: true },
  },
  reversed: true,
};

const copyToClipBoard = (textToCopy) => {
  navigator.clipboard.writeText(textToCopy);
  snackbarStore.showSnackbar("Link copiado");
};
</script>

<template>
  <v-container min-width="90vh">
    <p class="text-h6 font-weight-bold text-center">Nuevo link de pago</p>
    <v-card
      class="pa-5 my-5"
      variant="elevated"
      :loading="isPending"
      v-if="!isShowLinkResult"
    >
      <v-card-text class="pa-4">
        <p class="text-body-1 mb-10">Ingrese los datos del link de pago:</p>
        <v-form @submit.prevent="onSubmit">
          <v-row>
            <v-text-field
              label="Monto a pagar"
              variant="outlined"
              clearable
              maxlength="10"
              prepend-inner-icon="mdi-currency-usd"
              v-maska:amountUnmaskedValue.unmasked="options"
              v-model="amount.value.value"
              :error-messages="amount.errorMessage.value"
              :disabled="isPending"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              label="Referencia del pago"
              variant="outlined"
              clearable
              maxlength="100"
              v-model="reference.value.value"
              :error-messages="reference.errorMessage.value"
              :disabled="isPending"
            ></v-text-field>
          </v-row>
        </v-form>
        <v-row class="text-center mt-2">
          <v-col>
            <v-btn
              color="primary"
              variant="outlined"
              class="text-none"
              :disabled="isPending"
              :to="{ name: 'fintech-payins-list' }"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="primary"
              variant="outlined"
              class="text-none"
              @click="onSubmit"
              :disabled="isPending"
              >Crear</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="pa-5 my-5" variant="elevated" v-if="isShowLinkResult">
      <v-card-text class="pa-4">
        <p class="text-body-1 mb-10">Hemos generado tu link de pago:</p>
        <v-form @submit.prevent="onSubmit">
          <v-row>
            <v-text-field
              variant="outlined"
              maxlength="100"
              v-model="newLinkUri"
              disabled
            ></v-text-field>
            <v-btn variant="text" @click="copyToClipBoard(newLinkUri)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </v-row>
        </v-form>
        <v-alert
          text="Ten en cuenta que este es el único
          momento en que podras copiarlo desde el portal."
          type="info"
          color="deep-purple-accent-4"
          variant="tonal"
          class="my-5"
        ></v-alert>
        <v-row class="text-center mt-2">
          <v-col>
            <v-btn
              color="primary"
              variant="outlined"
              class="text-none"
              :disabled="isPending"
              :to="{ name: 'fintech-payins-list' }"
            >
              OK
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
