<script setup>
import axiosInstance from "@/lib/axios";
import { useCartStore, useLoaderStore, useSnackbarStore } from "@/store";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";

import emblem from "@/assets/logo-emblem-white.svg";
import logoname from "@/assets/logo-name-white.svg";

const router = useRouter();
const route = useRoute();

const loaderStore = useLoaderStore();
const { show: isShowLoader, message: loaderMessage } = storeToRefs(loaderStore);

const snackbarStore = useSnackbarStore();
const { show: isShowSnackbar, message: snackbarMessage } =
  storeToRefs(snackbarStore);

const cartStore = useCartStore();
const { cartCount } = storeToRefs(cartStore);

async function logout() {
  axiosInstance.post("/auth/logout");
  router.replace("/login");
}

const { mobile } = useDisplay();

const navDrawerIsOpen = ref(!mobile.value);
</script>

<template>
  <v-app>
    <v-header>
      <v-navigation-drawer v-model="navDrawerIsOpen" transition="none">
        <v-list nav dense>
          <v-list-group value="fintech">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-row>
                  <v-col cols="4"
                    ><v-icon icon="mdi-soundcloud" color="primary"> </v-icon>
                  </v-col>
                  <v-col>Fintech</v-col>
                </v-row>
              </v-list-item>
            </template>
            <v-list-item link :to="{ name: 'fintech-home' }" :exact="true">
              <v-row>
                <v-col cols="3"
                  ><v-icon icon="mdi-home" color="primary"> </v-icon>
                </v-col>
                <v-col><span class="font-weight-regular">Inicio</span></v-col>
              </v-row>
            </v-list-item>
            <v-list-item
              link
              :to="{ name: 'fintech-payins-list' }"
              :exact="true"
            >
              <v-row>
                <v-col cols="3"
                  ><v-icon icon="mdi-arrow-left-bold-circle" color="primary">
                  </v-icon>
                </v-col>
                <v-col><span class="font-weight-regular">Cobros</span></v-col>
              </v-row>
            </v-list-item>
            <v-list-item
              link
              :to="{ name: 'fintech-thirdparties-list' }"
              :exact="true"
            >
              <v-row>
                <v-col cols="3"
                  ><v-icon icon="mdi-account-multiple-outline" color="primary">
                  </v-icon>
                </v-col>
                <v-col><span class="font-weight-regular">Terceros</span></v-col>
              </v-row>
            </v-list-item>
            <v-list-item
              link
              :to="{ name: 'fintech-integrations' }"
              :exact="true"
              ><v-row>
                <v-col cols="3"
                  ><v-icon icon="mdi-console" color="primary"> </v-icon>
                </v-col>
                <v-col
                  ><span class="font-weight-regular">Integración</span></v-col
                >
              </v-row></v-list-item
            ></v-list-group
          >

          <v-list-group value="billPayment">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-row>
                  <v-col cols="4"
                    ><v-icon icon="mdi-newspaper" color="primary"> </v-icon>
                  </v-col>
                  <v-col>Facturas</v-col>
                </v-row>
              </v-list-item>
            </template>

            <v-list-item link :to="{ name: 'bills' }" :exact="true">
              <v-row>
                <v-col cols="3"
                  ><v-icon icon="mdi-magnify" color="primary"> </v-icon>
                </v-col>
                <v-col
                  ><span class="font-weight-regular">Disponibles</span></v-col
                >
              </v-row>
            </v-list-item>

            <v-list-item link :to="{ name: 'contracts-list' }" :exact="true">
              <v-row>
                <v-col cols="3"
                  ><v-icon icon="mdi-pen" color="primary"> </v-icon>
                </v-col>
                <v-col
                  ><span class="font-weight-regular">Contratos</span></v-col
                >
              </v-row>
            </v-list-item>

            <v-list-item
              link
              :to="{ name: 'paymentmethods-list' }"
              :exact="true"
            >
              <v-row>
                <v-col cols="3"
                  ><v-icon icon="mdi-credit-card-multiple" color="primary">
                  </v-icon>
                </v-col>
                <v-col
                  ><span class="font-weight-regular"
                    >Métodos de pago</span
                  ></v-col
                >
              </v-row>
            </v-list-item>

            <v-list-item link :to="{ name: 'transactions-list' }" :exact="true">
              <v-row>
                <v-col cols="3"
                  ><v-icon icon="mdi-check-all" color="primary"> </v-icon>
                </v-col>
                <v-col
                  ><span class="font-weight-regular">Transacciones</span></v-col
                >
              </v-row>
            </v-list-item>
          </v-list-group>

          <v-divider></v-divider>

          <v-list-item link @click="logout()">
            <v-row>
              <v-col cols="3"
                ><v-icon icon="mdi-logout" color="primary"> </v-icon>
              </v-col>
              <v-col><span class="font-weight-regular">Salir</span></v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar :elevation="2" color="teal-darken-4">
        <template v-slot:image>
          <v-img
            gradient="to top right, rgba(19,84,122,.8), rgba(90,49,244,.8)"
          ></v-img>
        </template>

        <template v-slot:prepend>
          <v-app-bar-nav-icon
            @click.stop="navDrawerIsOpen = !navDrawerIsOpen"
          ></v-app-bar-nav-icon>
        </template>

        <v-app-bar-title>
          <v-container class="d-flex align-center justify-center">
            <v-sheet color="transparent" width="50"
              ><v-img width="70" :src="emblem"></v-img
            ></v-sheet>
            <v-sheet color="transparent" width="90"
              ><v-img width="90" :src="logoname"></v-img
            ></v-sheet>
          </v-container>
        </v-app-bar-title>

        <v-btn ico :to="{ name: 'cart-start' }">
          <v-badge color="primary" :content="cartCount" inline
            ><v-icon>mdi-cart</v-icon></v-badge
          >
        </v-btn>
      </v-app-bar>
    </v-header>
    <v-main>
      <v-sheet class="mx-auto mt-5" max-width="860px" padding="10">
        <router-view></router-view>
      </v-sheet>
      <v-footer class="d-flex flex-column">
        <div class="px-4 py-2 text-center w-100">
          {{ new Date().getFullYear() }} — <strong>AloPay</strong>
        </div>
      </v-footer>

      <v-dialog v-model="isShowLoader" max-width="400">
        <v-card>
          <v-card-text class="text-md-center">
            <span class="text-none">{{ loaderMessage }}</span>
          </v-card-text>
          <v-progress-linear
            color="primary"
            height="6"
            indeterminate
            rounded
          ></v-progress-linear>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="isShowSnackbar" color="primary">
        <span class="text-none font-weight-bold">{{ snackbarMessage }}</span>

        <template v-slot:actions>
          <v-btn
            color="white"
            variant="text"
            :timeout="1000"
            @click="snackbarStore.hideSnackbar()"
          >
            <span class="text-none font-weight-bold">OK</span>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>
