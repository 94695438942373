<script setup>
import axiosInstance from "@/lib/axios";
import { formatAmount } from "@/lib/utils";
import { useLoaderStore, useSnackbarStore } from "@/store";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { vMaska } from "maska/vue";
import { useField, useForm } from "vee-validate";
import { defineExpose, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import * as yup from "yup";

const route = useRoute();
const router = useRouter();
const snackbarStore = useSnackbarStore();
const loaderStore = useLoaderStore();

const virtualAccountId = route.params.virtualAccountId;
const thirdpartyId = ref(null);
const isShowAssignBalance = ref(false);

const amountUnmaskedValue = ref("");
defineExpose({ amountUnmaskedValue });

// get org balance
async function getOrgBalance() {
  try {
    return (await axiosInstance.get(`/balance`)).data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.log(error);
    } else {
      throw error;
    }
  }
}

const {
  isPending: isPendingGetOrgBalance,
  data: dataGetOrgBalance,
  refetch: refetchGetOrgBalance,
} = useQuery({
  queryKey: ["getOrgBalance"],
  queryFn: getOrgBalance,
});

// get virtual account
async function getVirtualAccount() {
  try {
    const result = (
      await axiosInstance.get(`/virtual_accounts/${virtualAccountId}`)
    ).data;
    thirdpartyId.value = result.thirdparty_id;

    refetchGetThirdparty();
    return result;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.log(error);
    } else {
      throw error;
    }
  }
}

const {
  isPending: isPendingGetVirtualAccount,
  data: virtualAccountData,
  refetch: refetchGetVirtualAccount,
} = useQuery({
  queryKey: ["getVirtualAccount"],
  queryFn: getVirtualAccount,
});

// get thirdparty
async function getThirparty() {
  try {
    return (await axiosInstance.get(`/thirdparties/${thirdpartyId.value}`))
      .data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.log(error);
    } else {
      throw error;
    }
  }
}

const {
  isPending: isPendingGetThirdparty,
  data: thirdpartyData,
  refetch: refetchGetThirdparty,
} = useQuery({
  queryKey: ["getThirparty"],
  queryFn: getThirparty,
  enabled: false,
});

// assign balance
const options = {
  onMaska: (detail) => (amountUnmaskedValue.value = detail.unmasked),
  mask: "9.99#",
  tokens: {
    9: { pattern: /[0-9]/, repeated: true },
  },
  reversed: true,
};

const validationSchemaAssignBalance = yup.object().shape({
  amount: yup.string().required("El campo es requerido"),
});

const {
  handleSubmit: handleSubmitAssignBalance,
  handleReset: handleResetAssignBalance,
} = useForm({
  validationSchema: validationSchemaAssignBalance,
});

const { value: amountValue, error: amountError } = useField(
  "amount",
  validationSchemaAssignBalance
);

const { isPending: isPendingAssignBalance, mutate: mutateAssignBalance } =
  useMutation({
    mutationFn: async () => {
      loaderStore.showLoader();
      await axiosInstance.post(
        `/virtual_accounts/${virtualAccountId}/assign_balance`,
        {
          amount: amountUnmaskedValue.value,
        }
      );
    },
    onError: (error) => {
      if (error.response.data.code) {
        switch (error.response.data.code) {
          case "insufficient_balance":
            snackbarStore.showSnackbar("Balance insuficiente");
            break;
          default:
            snackbarStore.showSnackbar(
              "No pudimos asignar el balance. Intentalo de nuevo"
            );
        }
      } else {
        snackbarStore.showSnackbar(
          "No pudimos asignar el balance. Intentalo de nuevo"
        );
      }
    },
    onSuccess: () => {
      snackbarStore.showSnackbar("Balance asignado");
    },
    onSettled: () => {
      isShowAssignBalance.value = false;
      loaderStore.hideLoader();
      handleResetAssignBalance();
      refetchGetVirtualAccount();
      refetchGetOrgBalance();
    },
  });

const onSubmitAssignBalance = handleSubmitAssignBalance(() => {
  loaderStore.showLoader();
  mutateAssignBalance();
});
</script>

<template>
  <v-container>
    <p class="text-h6 font-weight-bold text-center">
      Detalles de cuenta virtual
    </p>
    <v-card
      class="pa-5 my-5"
      variant="elevated"
      :loading="isPendingGetThirdparty || isPendingGetVirtualAccount"
    >
      <v-card-text class="pa-4">
        <v-sheet
          class="text-body-1 py-2 font-weight-bold text-center"
          color="primary"
        >
          Balances
        </v-sheet>
        <v-table density="default">
          <thead>
            <tr class="text-subtitle-1 font-weight-bold">
              <th class="text-center">Nombre</th>
              <th class="text-center">Documento</th>
              <th class="text-center">Balance Org</th>
              <th class="text-center">Balance CV</th>
            </tr>
          </thead>
          <tbody v-if="thirdpartyData && dataGetOrgBalance">
            <tr class="t-10">
              <td class="text-center">{{ thirdpartyData.name }}</td>
              <td class="text-center">
                {{ thirdpartyData.document_type }}-{{
                  thirdpartyData.document_number
                }}
              </td>
              <td class="text-center">
                {{ formatAmount(dataGetOrgBalance.amount) }}
              </td>
              <td class="text-center">
                {{ formatAmount(virtualAccountData.balance) }}
              </td>
            </tr>
          </tbody>
        </v-table>

        <v-row class="text-center mt-2">
          <v-col>
            <v-btn
              color="primary"
              variant="outlined"
              class="text-none"
              @click="isShowAssignBalance = true"
              >Asignar Balance</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>

  <v-dialog v-model="isShowAssignBalance" max-width="400" persistent>
    <v-card>
      <template v-slot:prepend
        ><v-icon icon="mdi-information" color="primary"></v-icon>
      </template>

      <v-card-text>
        <p class="text-none">Que monto desea asignar?</p>
        <v-form @submit.prevent="onSubmitAssignBalance">
          <v-container>
            <v-row class="mt-2">
              <v-text-field
                label="Monto a asignar"
                variant="outlined"
                clearable
                maxlength="10"
                prepend-inner-icon="mdi-currency-usd"
                v-maska:amountUnmaskedValue.unmasked="options"
                v-model="amountValue"
                :error-messages="amountError"
                :disabled="isPendingAssignBalance"
              ></v-text-field>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn @click="isShowAssignBalance = false">
          <span class="text-none">Cancelar</span>
        </v-btn>

        <v-btn @click="onSubmitAssignBalance">
          <span class="text-none">Asignar</span>
        </v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>
