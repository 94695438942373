import vuetify from "@/plugins/vuetify";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "@mdi/font/css/materialdesignicons.css";
import { initializeApp } from "firebase/app";
import { createVPhoneInput } from "v-phone-input";
import "vuetify/styles";

import "flag-icons/css/flag-icons.min.css";

const vPhoneInput = createVPhoneInput({
  countryIconMode: "svg",
  countryLabel: "País",
  defaultCountry: "CO",
  invalidMessage: "Debe ser un teléfono valido",
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const firebaseConfig = {
  apiKey: "AIzaSyDJq2mePWTnVaqxPRmvhnkVfla6tkREIOs",
  authDomain: "data-services-prod-411812.firebaseapp.com",
  projectId: "data-services-prod-411812",
  storageBucket: "data-services-prod-411812.appspot.com",
  messagingSenderId: "944606270317",
  appId: "1:944606270317:web:dee26e30aad053c91ac064",
};

const app = initializeApp(firebaseConfig);

export const apiBaseUrl = process.env.VUE_APP_BASE_URL;

createApp(App)
  .use(router)
  .use(vuetify)
  .use(VueQueryPlugin)
  .use(pinia)
  .use(vPhoneInput)
  .mount("#app");
