import moment from "moment-timezone";

export const formatAmount = (amount: number, currency = "COP") => {
  return new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 2,
  })
    .format(amount)
    .replace(currency, "")
    .trim();
};

export const formatDateWithTime = (date: Date) => {
  const utc = moment.tz(date, "UTC");
  const tzDate = moment.tz(utc, "America/Bogota");
  return tzDate.format("YYYY-MM-DD HH:mm");
};

export const formatDate = (date: Date) => {
  const utc = moment.tz(date, "UTC");
  const tzDate = moment.tz(utc, "America/Bogota");
  return tzDate.format("YYYY-MM-DD");
};
