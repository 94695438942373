<script setup>
import { formatAmount } from "@/lib/utils";
import { useCartStore } from "@/store";
import { storeToRefs } from "pinia";

const cartStore = useCartStore();
const { cartCount, cartItems, cartTotal } = storeToRefs(cartStore);
</script>

<template>
  <div>
    <v-container>
      <p class="text-h6 text-center font-weight-bold">Carrito de pago</p>
    </v-container>

    <v-container v-if="cartCount == 0" style="height: 200px">
      <v-row align-content="center" justify="center" class="mt-5">
        <v-col class="text-body-12 text-center" cols="12">
          No tienes nada en tu carrito
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col>
          <v-btn
            variant="outlined"
            color="primary"
            class="text-none"
            :to="{ name: 'bills' }"
            >Ir a inicio</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="cartCount > 0">
      <v-table>
        <thead>
          <tr class="text-subtitle-1 font-weight-bold">
            <th class="text-center">Proveedor</th>
            <th class="text-center">Contrato</th>
            <th class="text-center">Monto</th>
            <th class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in cartItems" :key="item.name">
            <td class="text-center">{{ item.billerName }}</td>
            <td class="text-center">{{ item.contractNumber }}</td>
            <td class="text-center">
              {{ formatAmount(item.amount) }}
            </td>
            <td>
              <v-btn
                target="_blank"
                variant="outlined"
                color="primary"
                icon="mdi-trash-can"
                size="25"
                @click="cartStore.removeItem(item.id)"
              ></v-btn>
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="text-center">
              <span class="font-weight-bold">Total</span>
            </td>
            <td class="text-center">
              {{ formatAmount(cartTotal) }}
            </td>
            <td></td>
          </tr>
        </tbody>
      </v-table>
      <v-container>
        <v-row class="text-center">
          <v-col>
            <v-btn
              variant="outlined"
              color="primary"
              class="text-none"
              :to="{ name: 'bills' }"
              >Ir a inicio</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              variant="outlined"
              color="primary"
              class="text-none"
              :to="{ name: 'cart-choose-method' }"
              >Continuar a pago</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <InitLoading :show="isPending" />
  </div>
</template>
