<script setup>
import axiosInstance from "@/lib/axios";
import { useLoaderStore, useSnackbarStore } from "@/store";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { useField, useForm } from "vee-validate";
import { onMounted, ref } from "vue";
import * as yup from "yup";

const loaderStore = useLoaderStore();

const apiKey = ref("");
const apiKeyMasked = ref("");
const integrationKey = ref("");
const integrationKeyMasked = ref("");
const tab = ref("one");
const isShowRotateConfirmation = ref(false);

function _maskKey(key) {
  return (
    key.split("_").at(0) + "_" + key.split("_").at(1) + "_" + "*".repeat(20)
  );
}

async function getKeys() {
  try {
    const data = (await axiosInstance.get("/integrations/keys")).data;

    apiKey.value = data.api_key;
    apiKeyMasked.value = _maskKey(apiKey.value);
    integrationKey.value = data.integration_key;
    integrationKeyMasked.value = _maskKey(integrationKey.value);

    return data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.log(error);
    } else {
      throw error;
    }
  }
}

const {
  isPending,
  isError,
  data: keysData,
  refetch,
  error,
} = useQuery({
  queryKey: ["getKeys"],
  queryFn: getKeys,
  cacheTime: 0,
  disabled: true,
});

const snackbarStore = useSnackbarStore();

const copyToClipBoard = (textToCopy) => {
  navigator.clipboard.writeText(textToCopy);
  snackbarStore.showSnackbar("Llave copiada");
};

// Get webhook URL
async function getWebhookUrl() {
  try {
    const data = (await axiosInstance.get("/integrations/webhook")).data;
    webhookUrlValue.value = data.webhook_url ?? "";
    return data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.log(error);
    } else {
      throw error;
    }
  }
}

const { isPending: webhookUrlIsPending, refetch: webhookUrlRefetch } = useQuery(
  {
    queryKey: ["getWebhookUrl"],
    queryFn: getWebhookUrl,
    cacheTime: 0,
    disabled: true,
  }
);

// Rotate keys
const { mutate: rotateKeysMutate } = useMutation({
  mutationFn: async () => {
    loaderStore.showLoader();
    await axiosInstance.post(`/integrations/recreate_keys`);
    await refetch();
  },
  onError: (error) => {
    console.log(error);
    snackbarStore.showSnackbar(
      "No pudimos rotar las llaves, vuelve a intentar"
    );
  },
  onSuccess: () => {
    snackbarStore.showSnackbar("Llaves rotadas");
  },
  onSettled: () => {
    isShowRotateConfirmation.value = false;
    loaderStore.hideLoader();
  },
});

// Webhook Url

const { mutate: webhookUrlEditMutate } = useMutation({
  mutationFn: async () => {
    loaderStore.showLoader();
    await axiosInstance.put(`/integrations/webhook`, {
      webhook_url: webhookUrlValue.value,
    });
    await webhookUrlRefetch();
  },
  onError: (error) => {
    console.log(error);
    snackbarStore.showSnackbar(
      "No pudimos actualizar la URL de webhooks, vuelve a intentar"
    );
  },
  onSuccess: () => {
    snackbarStore.showSnackbar("URL de webhooks actualizada");
  },
  onSettled: () => {
    isShowRotateConfirmation.value = false;
    loaderStore.hideLoader();
  },
});

const webhookValidationSchema = yup.object().shape({
  webhookUrl: yup
    .string()
    .nullable() // Allows the field to be null or undefined
    .test("is-valid-url-or-empty", "Debe ser una URL valida", function (value) {
      if (!value) return true; // If the value is empty, pass the validation

      // Define the regex for URL validation
      const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-]*)*\/?$/;
      return urlRegex.test(value); // Apply the regex check if the value is not empty
    }),
});
const { handleSubmit: webhookHandleSubmit } = useForm({
  validationSchema: webhookValidationSchema,
});
const { value: webhookUrlValue, errorMessage: webhookUrlErrorMessage } =
  useField("webhookUrl", webhookHandleSubmit);

const webhookUrlEditOnSubmit = webhookHandleSubmit((values) => {
  webhookUrlEditMutate(values);
});

onMounted(async () => {
  getWebhookUrl();
  getKeys();
});
</script>

<template>
  <v-container min-width="90vh">
    <p class="text-h6 font-weight-bold text-center">Integración</p>

    <v-card
      class="pa-5 my-5"
      variant="elevated"
      :loading="isPending || webhookUrlIsPending"
    >
      <v-tabs v-model="tab" bg-color="primary">
        <v-tab value="one" class="text-none font-weight-bold">Llaves</v-tab>
        <v-tab value="two" class="text-none font-weight-bold">Webhooks</v-tab>
      </v-tabs>

      <v-card-text class="pa-4" v-if="tab === 'one'">
        <p class="text-body-1 mb-5 font-weight-bold">API</p>
        <v-row>
          <v-text-field
            variant="outlined"
            maxlength="100"
            v-model="apiKeyMasked"
            disabled
          ></v-text-field>
          <v-btn class="pt-5" variant="text" @click="copyToClipBoard(apiKey)">
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </v-row>

        <p class="text-body-1 mb-5 font-weight-bold">Integridad</p>
        <v-row>
          <v-text-field
            variant="outlined"
            maxlength="100"
            v-model="integrationKeyMasked"
            disabled
          ></v-text-field>
          <v-btn
            class="pt-5"
            variant="text"
            @click="copyToClipBoard(integrationKey)"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </v-row>

        <v-alert
          text="Ten en cuenta que al rotar las llaves, tus llaves actuales dejaran de funcionar."
          type="info"
          color="deep-purple-accent-4"
          variant="tonal"
          class="my-5"
        ></v-alert>
        <v-row class="text-center mt-2">
          <v-col>
            <v-btn
              color="primary"
              variant="outlined"
              class="text-none"
              :disabled="isPending"
              @click="isShowRotateConfirmation = true"
            >
              Rotar llaves
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pa-4" v-if="tab === 'two'">
        <p class="text-body-1 mb-5 font-weight-bold">URL</p>
        <v-form @submit.prevent="webhookUrlEditOnSubmit">
          <v-row>
            <v-text-field
              variant="outlined"
              maxlength="100"
              v-model="webhookUrlValue"
              :error-messages="webhookUrlErrorMessage"
              :disabled="webhookUrlIsPending"
            ></v-text-field>
          </v-row>
          <v-row class="text-center mt-2">
            <v-col>
              <v-btn
                color="primary"
                variant="outlined"
                class="text-none"
                :disabled="webhookUrlIsPending"
                @click="webhookUrlEditOnSubmit"
              >
                Actualizar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>

  <v-dialog v-model="isShowRotateConfirmation" max-width="400" persistent>
    <v-card>
      <template v-slot:prepend
        ><v-icon icon="mdi-information" color="primary"></v-icon>
      </template>

      <v-card-text>
        <p class="text-none">Esta seguro de rotar las llaves?</p>
      </v-card-text>

      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn @click="isShowRotateConfirmation = false">
          <span class="text-none">Cancelar</span>
        </v-btn>

        <v-btn @click="rotateKeysMutate">
          <span class="text-none">Rotar</span>
        </v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>
