<script setup>
import { toRefs, defineProps } from "vue";

const props = defineProps(["show", "message"]);
const { show, message } = toRefs(props);
</script>

<template>
  <v-container v-if="show" style="height: 200px">
    <v-row align-content="center" class="fill-height" justify="center">
      <v-col class="text-subtitle-1 text-center" cols="12">
        <span class="text-none">{{
          message ?? "Haciendo un poco de magia para obtener tus datos"
        }}</span>
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          color="primary"
          height="6"
          indeterminate
          rounded
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>
