<script setup>
import axiosInstance from "@/lib/axios";
import { useLoaderStore, useSnackbarStore } from "@/store";
import { useMutation } from "@tanstack/vue-query";
import { useField, useForm } from "vee-validate";
import { defineEmits, defineProps, ref, toRefs } from "vue";
import * as yup from "yup";

const props = defineProps([
  "id",
  "contractNumber",
  "sourceImgUrl",
  "sourceName",
  "sourceIdentifier",
  "description",
]);
const {
  id: contractId,
  contractNumber,
  sourceImgUrl,
  sourceName,
  sourceIdentifier,
  description,
} = toRefs(props);

const loaderStore = useLoaderStore();
const snackbarStore = useSnackbarStore();
const isShowConfirmationRemoval = ref(false);
const isShowEditContract = ref(false);
const emit = defineEmits(["refreshContractList"]);

// Remove mutation
const { mutate: removeMutate } = useMutation({
  mutationFn: async () => {
    loaderStore.showLoader();
    await axiosInstance.delete(`/contracts/${contractId.value}`);
  },
  onError: (error) => {
    console.log(error);
    snackbarStore.showSnackbar(
      "No pudimos remover el contrato, vuelve a intentar"
    );
  },
  onSuccess: () => {
    snackbarStore.showSnackbar("Contrato removido");
    emit("refreshContractList");
  },
  onSettled: () => {
    isShowConfirmationRemoval.value = false;
    loaderStore.hideLoader();
  },
});

// Edit contract form and mutation
const validationSchema = yup.object().shape({
  newDescription: yup
    .string()
    .max(20)
    .required("Debe ingresar una descripción"),
});
const { handleSubmit: handleEditSubmit, handleReset: handleEditReset } =
  useForm({
    validationSchema,
  });
const newDescription = useField("newDescription", validationSchema);

const { isPending: isEditPending, mutate: editMutate } = useMutation({
  mutationFn: async ({ newDescription }) => {
    loaderStore.showLoader();
    await axiosInstance.put(`/contracts/${contractId.value}`, {
      description: newDescription,
    });
  },
  onError: (error) => {
    console.log(error);
    snackbarStore.showSnackbar(
      "No pudimos actualizar tu contrato, vuelve a intentar"
    );
  },
  onSuccess: () => {
    snackbarStore.showSnackbar("Descripción actualizada");
    emit("refreshContractList");
  },
  onSettled: () => {
    isShowEditContract.value = false;
    loaderStore.hideLoader();
    handleEditReset();
  },
});

const onEditSubmit = handleEditSubmit(async (values) => {
  isShowConfirmationRemoval.value = false;
  loaderStore.showLoader();
  editMutate(values);
});
</script>

<template>
  <v-container>
    <v-card elevation="2">
      <v-card-text class="headline">
        <v-row no-gutters>
          <v-col cols="3">
            <v-img :width="60" :src="sourceImgUrl"></v-img>
          </v-col>
          <v-col>
            <span class="font-weight-bold">Proveedor</span>
            <p>{{ sourceName }}</p></v-col
          >
          <v-col>
            <span class="font-weight-bold">Contrato</span>
            <p>{{ contractNumber }}</p></v-col
          >
          <v-col>
            <span class="font-weight-bold">Descripción</span>
            <p>{{ description }}</p></v-col
          >
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-row>
              <v-col class="mc-2">
                <v-btn
                  :href="billFileUrl"
                  target="_blank"
                  variant="outlined"
                  color="warning"
                  class="text-none"
                  @click="isShowConfirmationRemoval = true"
                  >Remover
                </v-btn>
              </v-col>
              <v-col class="mc-2">
                <v-btn
                  :href="billFileUrl"
                  target="_blank"
                  variant="outlined"
                  color="primary"
                  class="text-none"
                  @click="isShowEditContract = true"
                  >Editar descripción
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>

  <v-dialog v-model="isShowConfirmationRemoval" max-width="400" persistent>
    <v-card>
      <template v-slot:prepend
        ><v-icon icon="mdi-information" color="primary"></v-icon>
      </template>

      <v-card-text>
        <p class="text-none">Esta seguro de remover el contrato?</p>
      </v-card-text>

      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn @click="isShowConfirmationRemoval = false">
          <span class="text-none" @click="handleEditReset">Cancelar</span>
        </v-btn>

        <v-btn @click="removeMutate">
          <span class="text-none">Remover</span>
        </v-btn>
      </template>
    </v-card>
  </v-dialog>

  <v-dialog v-model="isShowEditContract" max-width="400" persistent>
    <v-card>
      <template v-slot:prepend
        ><v-icon icon="mdi-information" color="primary"></v-icon>
      </template>

      <v-card-text>
        <p class="text-none mb-2">
          Ingrese la nueva descripción para el contrato
        </p>
        <v-form @submit.prevent="onEditSubmit">
          <v-container>
            <v-row>
              <v-text-field
                label="Descripción"
                variant="outlined"
                clearable
                maxlength="20"
                v-model="newDescription.value.value"
                :error-messages="newDescription.errorMessage.value"
                :disabled="isEditPending"
              ></v-text-field>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn @click="isShowEditContract = false">
          <span class="text-none" @click="handleEditReset">Cancelar</span>
        </v-btn>

        <v-btn @click="onEditSubmit">
          <span class="text-none">Editar</span>
        </v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>
