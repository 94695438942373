<script setup>
import axiosInstance from "@/lib/axios";
import {
  useCartStore,
  usePaymentDetailsStore,
  useSnackbarStore,
} from "@/store";
import { useMutation } from "@tanstack/vue-query";
import { AxiosError } from "axios";
import { storeToRefs } from "pinia";
import { Form, useField, useForm } from "vee-validate";
import { useRouter } from "vue-router";
import * as yup from "yup";

const router = useRouter();
const snackbarStore = useSnackbarStore();
const paymentDetailsStore = usePaymentDetailsStore();
const { details } = storeToRefs(paymentDetailsStore);
const cartStore = useCartStore();
const { cartTotal, cartItemIds } = storeToRefs(cartStore);

const validationSchema = yup.object().shape({
  confirmationCode: yup
    .number()
    .typeError("Debe ser un número")
    .required("El campo es requerido"),
});

const { handleSubmit } = useForm({
  validationSchema,
});

const {
  value: confirmationCodeValue,
  errorMessage: confirmationCodeErrorMessage,
} = useField("confirmationCode", validationSchema);

const { isPending, mutate } = useMutation({
  mutationFn: () =>
    axiosInstance.post(
      `/payment_methods/${details.value.paymentMethodId}/confirm_otp`,
      {
        otp_code: confirmationCodeValue.value,
      }
    ),
  onSuccess: () => {
    mutatePayment();
  },
  onError: (error) => {
    console.error(error);
    if (
      error instanceof AxiosError &&
      error.response &&
      error.response.status === 400
    ) {
      switch (error.response.data.code) {
        case "incorrect_payment_confirmation_code":
          snackbarStore.showSnackbar("El código es incorrecto");
          break;
        default:
          snackbarStore.showSnackbar(
            "No pudimos confirmar el OTP, intenta de nuevo"
          );
      }
    } else {
      snackbarStore.showSnackbar(
        "No pudimos confirmar el OTP, intenta de nuevo"
      );
    }
  },
});

const { isPending: isPendingPayment, mutate: mutatePayment } = useMutation({
  mutationFn: () =>
    axiosInstance.post(
      `/payments/payin`,
      {
        method_type: "PAYMENT_METHOD",
        details: {
          payment_method_id: details.value.paymentMethodId,
          amount: cartTotal.value,
          otp: confirmationCodeValue.value,
        },
        bill_ids: cartItemIds.value,
      },
      { timeout: 30000 }
    ),
  onSuccess: (response) => {
    cartStore.clear();
    router.replace({ name: "payments-trx-confirmation" });
  },
  onError: (error) => {
    console.error(error);
    snackbarStore.showSnackbar(
      "No pudimos cobrar de tu método de pago, intenta de nuevo"
    );
  },
});

const onSubmit = handleSubmit((values) => {
  mutate(values);
});
</script>

<template>
  <div>
    <v-container>
      <p class="text-h6 font-weight-bold text-center">
        Confirmación de método de pago
      </p>

      <v-card
        class="my-5 pa-5"
        variant="elevated"
        :loading="isPending || isPendingPayment"
      >
        <v-card-text>
          <p class="text-center mb-10">
            Por favor ingresa el código que hemos enviado a tu correo para
            confirmar el cobro:
          </p>
          <div>
            <Form @submit.prevent="onSubmit">
              <v-row>
                <v-text-field
                  variant="outlined"
                  clearable
                  label="Código de confirmación"
                  maxlength="6"
                  v-model="confirmationCodeValue"
                  :error-messages="confirmationCodeErrorMessage"
                  :disabled="isPending || isPendingPayment"
                ></v-text-field>
              </v-row>
              <v-row class="text-center">
                <v-col>
                  <v-btn
                    variant="outlined"
                    color="primary"
                    class="text-none"
                    :to="{ name: 'cart-choose-method' }"
                    :disabled="isPending || isPendingPayment"
                    >Ir al métodos de pago</v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn
                    variant="outlined"
                    color="primary"
                    class="text-none"
                    :disabled="isPending || isPendingPayment"
                    @click="onSubmit"
                    >Confirmar</v-btn
                  >
                </v-col>
              </v-row>
            </Form>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
