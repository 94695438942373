<script setup>
import ContractCard from "@/components/ContractCard.vue";
import InitLoading from "@/components/InitLoading.vue";
import axiosInstance from "@/lib/axios";
import { useQuery } from "@tanstack/vue-query";
import { onMounted } from "vue";

async function getContracts() {
  try {
    return (await axiosInstance.get("/contracts")).data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.log(error);
    } else {
      throw error;
    }
  }
}

const {
  isPending,
  isError,
  data: contractsData,
  refetch,
  error,
} = useQuery({
  queryKey: ["getContracts"],
  queryFn: getContracts,
});

onMounted(async () => {
  // skip
});
</script>

<template>
  <div>
    <v-container>
      <v-row class="justify-center">
        <v-col>
          <v-btn
            class="text-none"
            color="primary"
            variant="outlined"
            :to="{ name: 'contracts-create' }"
            block
          >
            Registrar un contrato
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <p class="text-h6 text-center font-weight-bold">Contratos</p>
    </v-container>

    <v-container
      v-if="contractsData && contractsData.length == 0"
      style="height: 200px"
    >
      <v-row align-content="center" class="fill-height" justify="center">
        <v-col class="text-body-12 text-center" cols="12">
          No tienes ningun contrato registrado
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="contractsData" fluid>
      <v-row v-for="contract in contractsData" :key="contract.contract_number">
        <ContractCard
          :id="contract.id"
          :contractNumber="contract.contract_number"
          :sourceImgUrl="contract.source_provider.img_url"
          :sourceIdentifier="contract.source_provider.code"
          :sourceName="contract.source_provider.name"
          :description="contract.description"
          @refresh-contract-list="refetch"
        />
      </v-row>
    </v-container>

    <v-container v-else-if="isError" style="height: 400px">
      {{ error }}
    </v-container>

    <InitLoading :show="isPending" />
  </div>
</template>
