<script setup>
import axiosInstance from "@/lib/axios";
import { useSnackbarStore } from "@/store";
import { useMutation } from "@tanstack/vue-query";
import { useField, useForm } from "vee-validate";
import { ref } from "vue";
import { useRouter } from "vue-router";
import * as yup from "yup";

const router = useRouter();
const snackbarStore = useSnackbarStore();
const generalError = ref("");

const sourceList = [
  {
    name: "Triple A",
    code: "AAA",
  },
  {
    name: "Gases del Caribe",
    code: "GDC",
  },
  {
    name: "Air-e",
    code: "AIRE",
  },
];

const validationSchema = yup.object().shape({
  contractNumber: yup
    .number()
    .typeError("Debe ser un número")
    .required("El campo es requerido"),
  description: yup.string().max(20),
  sourceCode: yup.string().test({
    name: "is-set",
    test(value, ctx) {
      return value ?? ctx.createError({ message: "Debe escoger una opción" });
    },
  }),
});

const { handleSubmit } = useForm({
  validationSchema,
});
const contractNumber = useField("contractNumber", validationSchema);
const description = useField("description", validationSchema);
const sourceCode = useField("sourceCode", validationSchema);

const { isPending, mutate } = useMutation({
  mutationFn: async ({ contractNumber, description, sourceCode }) =>
    await axiosInstance.post("/contracts", {
      contract_number: contractNumber,
      description: description,
      source: sourceCode,
    }),
  onSuccess: () => {
    snackbarStore.showSnackbar("Contrato registrado con exito");
    router.replace("/contracts");
  },
  onError: (error) => {
    console.error(error);
    if (error.response && error.response.status === 400) {
      switch (error.response.data.code) {
        case "source_contract_check_error":
        case "duplicated_contract":
          snackbarStore.showSnackbar("Este contrato ya esta registrado");
          break;
        default:
          snackbarStore.showSnackbar(
            "No pudimos confirmar el contrato, por favor verificalo y vuelve a intentar"
          );
      }
    } else {
      snackbarStore.showSnackbar(
        "No pudimos confirmar el contrato, por favor verificalo y vuelve a intentar"
      );
    }
  },
});

const onSubmit = handleSubmit(async (values) => {
  mutate(values);
});
</script>

<template>
  <v-container min-width="90vh">
    <p class="text-h6 font-weight-bold text-center">Nuevo contrato</p>
    <v-card class="pa-5 my-5" variant="elevated" :loading="isPending">
      <v-card-text class="pa-4">
        <p class="text-body-1 mb-10">
          Ingrese los datos del contrato o poliza a registrar para auto
          consulta:
        </p>
        <v-form @submit.prevent="onSubmit">
          <v-row>
            <v-text-field
              label="Número de contrato o poliza"
              variant="outlined"
              clearable
              maxlength="10"
              v-model="contractNumber.value.value"
              :error-messages="contractNumber.errorMessage.value"
              :disabled="isPending"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              label="Descripción"
              variant="outlined"
              clearable
              maxlength="20"
              v-model="description.value.value"
              :error-messages="description.errorMessage.value"
              :disabled="isPending"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-select
              :items="sourceList"
              item-title="name"
              item-value="code"
              label="Servicio"
              variant="outlined"
              v-model="sourceCode.value.value"
              :error-messages="sourceCode.errorMessage.value"
              :disabled="isPending"
            >
              <template v-slot:item="{ props }">
                <v-list-item v-bind="props"></v-list-item>
              </template>
            </v-select>
          </v-row>
        </v-form>
        <v-row class="text-center mt-2">
          <v-col>
            <v-btn
              color="primary"
              variant="outlined"
              class="text-none"
              :disabled="isPending"
              :to="{ name: 'contracts-list' }"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="primary"
              variant="outlined"
              class="text-none"
              @click="onSubmit"
              :disabled="isPending"
              >Registrar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
