<script setup>
import InitLoading from "@/components/InitLoading.vue";
import axiosInstance from "@/lib/axios";
import { useLoaderStore, useSnackbarStore } from "@/store";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const loaderStore = useLoaderStore();
const snackbarStore = useSnackbarStore();
const isShowConfirmationRemoval = ref(false);

const menuActions = (thirdparty) => {
  const items = [
    {
      title: "Editar",
      fn: () => {
        router.push({ name: "home" });
      },
    },
    {
      title: "Remover",
      fn: () => {
        isShowConfirmationRemoval.value = true;
      },
    },
  ];

  if (thirdparty.virtual_account_id) {
    items.push({
      title: "Ver cuenta virtual",
      fn: () => {
        router.push({
          name: "fintech-virtual-accounts-details",
          params: { virtualAccountId: thirdparty.virtual_account_id },
        });
      },
    });
  } else {
    items.push({
      title: "Crear cuenta virtual",
      fn: () => {
        mutateCreateVirtualAccount({ thirdpartyId: thirdparty.id });
      },
    });
  }

  return items;
};

async function getThirparties() {
  try {
    return (await axiosInstance.get("/thirdparties")).data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.log(error);
    } else {
      throw error;
    }
  }
}

const {
  isPending,
  isError,
  data: thirdpartiesData,
  refetch,
  error,
} = useQuery({
  queryKey: ["getThirparties"],
  queryFn: getThirparties,
});

// Create virtual account
const { mutate: mutateCreateVirtualAccount } = useMutation({
  mutationFn: async ({ thirdpartyId }) => {
    loaderStore.showLoader();
    await axiosInstance.post(`/virtual_accounts`, {
      thirdparty_id: thirdpartyId,
    });
  },
  onError: (error) => {
    if (error.response.data.code) {
      switch (error.response.data.code) {
        case "virtual_account_already_exists":
          snackbarStore.showSnackbar("La cuenta virtual ya existe");
          break;
        default:
          snackbarStore.showSnackbar(
            "No pudimos crear la cuenta virtual, vuelve a intentar"
          );
      }
    } else {
      snackbarStore.showSnackbar(
        "No pudimos crear la cuenta virtual, vuelve a intentar"
      );
    }
  },
  onSuccess: () => {
    snackbarStore.showSnackbar("Cuenta virtual creada exitosamente");
    refetch();
  },
  onSettled: () => {
    loaderStore.hideLoader();
  },
});
</script>

<template>
  <div>
    <v-container>
      <v-row class="justify-center">
        <v-col>
          <v-btn
            class="text-none"
            color="primary"
            variant="outlined"
            :to="{ name: 'fintech-thirdparties-create' }"
            block
          >
            Registrar un tercero
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <p class="text-h6 text-center font-weight-bold">Terceros</p>
    </v-container>

    <v-container
      v-if="thirdpartiesData && thirdpartiesData.length == 0"
      style="height: 200px"
    >
      <v-row align-content="center" justify="center" class="mt-5">
        <v-col class="text-body-12 text-center" cols="12">
          No tienes terceros registrados todavía
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="thirdpartiesData && thirdpartiesData.length > 0">
      <v-table density="default">
        <thead>
          <tr class="text-subtitle-1 font-weight-bold">
            <th class="text-center">Nombre</th>
            <th class="text-center">Documento</th>
            <th class="text-center">E-mail</th>
            <th class="text-center">Teléfono</th>
            <th class="text-center">Cuenta Virtual</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in thirdpartiesData" :key="item.name" class="t-10">
            <td class="text-center">{{ item.name }}</td>
            <td class="text-center">
              {{ item.document_type }}-{{ item.document_number }}
            </td>
            <td class="text-center">{{ item.email }}</td>
            <td class="text-center">{{ item.phone_number }}</td>
            <td class="text-center">
              <v-icon
                v-if="!item.virtual_account_id"
                icon="mdi-minus-circle"
                color="grey"
              ></v-icon>
              <v-icon
                v-if="item.virtual_account_id"
                icon="mdi-checkbox-marked-circle"
                color="green"
              ></v-icon>
            </td>
            <td>
              <v-btn
                :id="`menu-activator-${item.document_number}`"
                variant="flat"
              >
                <v-icon icon="mdi-dots-vertical" color="primary"> </v-icon>
              </v-btn>
              <v-menu
                :activator="`#menu-activator-${item.document_number}`"
                location="start"
              >
                <v-list>
                  <v-list-item
                    v-for="(option, index) in menuActions(item)"
                    :key="option + '' + index"
                    :value="option + '' + index"
                    @click="option.fn()"
                  >
                    <v-list-item-title
                      ><v-span class="text-none">{{
                        option.title
                      }}</v-span></v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </v-table>

      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="4"
          rounded="circle"
        ></v-pagination>
      </div>
    </v-container>

    <InitLoading :show="isPending" />
  </div>
</template>
