<script setup>
import InitLoading from "@/components/InitLoading.vue";
import axiosInstance from "@/lib/axios";
import { formatAmount, formatDate } from "@/lib/utils";
import { useQuery } from "@tanstack/vue-query";
import { ref } from "vue";

async function getPayins() {
  try {
    return (await axiosInstance.get("/payins")).data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.log(error);
    } else {
      throw error;
    }
  }
}

const {
  isPending,
  isError,
  data: payinsData,
  refetch,
  error,
} = useQuery({
  queryKey: ["getPayins"],
  queryFn: getPayins,
});

const isShowDetails = ref(false);

const payinDetails = ref(null);

const colorClass = (status) => {
  if (status == "APPROVED") {
    return { class: "success", text: "Confirmada" };
  } else if (status == "PENDING") {
    return { class: "warning", text: "Pendiente" };
  } else if (status == "DECLINED") {
    return { class: "error", text: "Declinada" };
  }
};
</script>

<template>
  <div>
    <v-container>
      <v-row class="justify-center">
        <v-col>
          <v-btn
            class="text-none"
            color="primary"
            variant="outlined"
            :to="{ name: 'fintech-payment-link-create' }"
            block
          >
            Crear un link de pago
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <p class="text-h6 text-center font-weight-bold">Cobros</p>
    </v-container>

    <v-container
      v-if="payinsData && payinsData.length == 0"
      style="height: 200px"
    >
      <v-row align-content="center" justify="center" class="mt-5">
        <v-col class="text-body-12 text-center" cols="12">
          No tienes payins registrados todavía
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="payinsData && payinsData.length > 0">
      <v-table density="default">
        <thead>
          <tr class="text-subtitle-1 font-weight-bold">
            <th class="text-center">Fecha</th>
            <th class="text-center">Tipo</th>
            <th class="text-center">Monto</th>
            <th class="text-center">Referencia</th>
            <th class="text-center">Estado</th>
            <th class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in payinsData" :key="item.name" class="t-10">
            <td class="text-center">{{ formatDate(item.created_at) }}</td>
            <td class="text-center">{{ item.payment_method_type }}</td>
            <td class="text-center">
              {{ formatAmount(item.amount) }}
            </td>
            <td class="text-center">{{ item.reference }}</td>
            <td class="text-center">
              <v-chip :color="colorClass(item.status).class">
                <span class="text-none font-weight-bold">{{
                  colorClass(item.status).text
                }}</span></v-chip
              >
            </td>
            <td class="text-center">
              <v-btn
                class="text-none"
                color="primary"
                variant="text"
                @click="
                  payinDetails = item;
                  isShowDetails = true;
                "
              >
                Detalle
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-table>

      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="4"
          rounded="circle"
        ></v-pagination>
      </div>
    </v-container>

    <InitLoading :show="isPending" />

    <v-dialog v-model="isShowDetails" max-width="400" persistent>
      <v-card>
        <template v-slot:prepend
          ><v-icon icon="mdi-information" color="primary"></v-icon>
        </template>

        <v-card-text>
          <v-table>
            <thead>
              <tr class="text-subtitle-1 font-weight-bold">
                <th class="text-center">Monto</th>
                <th class="text-center">Motivo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">Payin</td>
                <td class="text-center">
                  {{ formatAmount(payinDetails.amount) }}
                </td>
              </tr>
              <tr>
                <td class="text-center">Comisión</td>
                <td class="text-center">
                  {{ formatAmount(payinDetails.fee) }}
                </td>
              </tr>
              <tr>
                <td class="text-center">IVA</td>
                <td class="text-center">
                  {{ formatAmount(payinDetails.tax) }}
                </td>
              </tr>
              <tr>
                <td class="text-center">GMF</td>
                <td class="text-center">
                  {{ formatAmount(payinDetails.gmf) }}
                </td>
              </tr>
              <tr>
                <td class="text-center">Payin Neto</td>
                <td class="text-center">
                  {{ formatAmount(payinDetails.net_amount) }}
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>

        <template v-slot:actions>
          <v-spacer></v-spacer>

          <v-btn @click="isShowDetails = false">
            <span class="text-none">OK</span>
          </v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
