<script setup>
import { useField, useForm } from "vee-validate";
import * as yup from "yup";

import axiosInstance from "@/lib/axios";
import { useSnackbarStore } from "@/store";
import { useMutation } from "@tanstack/vue-query";
import { useRouter } from "vue-router";

import emblem from "@/assets/logo-emblem.svg";
import logoname from "@/assets/logo-name.svg";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Debes ingresar un email valido")
    .matches(/^(?!.*@[^,]*,)/)
    .required("El campo es requerido"),
  password: yup.string().min(8).required("El campo es requerido"),
});

const { handleSubmit } = useForm({
  validationSchema,
});

const router = useRouter();
const snackbarStore = useSnackbarStore();

const email = useField("email", validationSchema);
const password = useField("password", validationSchema);

const { isPending, mutate } = useMutation({
  mutationFn: ({ email, password }) =>
    axiosInstance.postForm("/auth/login", {
      username: email.trim().toLowerCase(),
      password: password,
    }),
  onSuccess: () => {
    router.replace({ name: "fintech-home" });
  },
  onError: (error) => {
    console.error(error);
    snackbarStore.showSnackbar("No pudimos autenticarte, intenta de nuevo");
  },
});

const onSubmit = handleSubmit((values) => {
  mutate(values);
});
</script>

<template>
  <v-container class="fill-height justify-center">
    <v-card
      class="px-10 mx-10"
      variant="elevated"
      width="500"
      :loading="isPending"
    >
      <v-card-text class="pa-4">
        <v-container class="d-flex justify-center align-center ma-0">
          <v-sheet width="70">
            <v-img :src="emblem"></v-img>
          </v-sheet>
          <v-sheet width="100">
            <v-img :src="logoname"></v-img>
          </v-sheet>
        </v-container>
        <v-form @submit.prevent="onSubmit">
          <v-row
            ><v-text-field
              label="E-mail"
              variant="outlined"
              clearable
              v-model="email.value.value"
              :error-messages="email.errorMessage.value"
              :disabled="isPending"
            ></v-text-field
          ></v-row>

          <v-row
            ><v-text-field
              label="Contraseña"
              type="password"
              variant="outlined"
              clearable
              v-model="password.value.value"
              :error-messages="password.errorMessage.value"
              :disabled="isPending"
            ></v-text-field
          ></v-row>
        </v-form>

        <v-container class="d-flex justify-center align-center ma-0 ga-3">
          <v-sheet width="70">
            <v-btn color="primary" variant="plain" @click="onSubmit"
              >Entrar</v-btn
            >
          </v-sheet>
          <v-sheet width="100">
            <router-link to="/register" replace>
              <v-btn color="primary" variant="plain" type="button"
                >Registro</v-btn
              >
            </router-link>
          </v-sheet>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
