<template>
  <router-view />
</template>

<script setup></script>

<style lang="scss">
$body-font-family: Avenir, Helvetica, Arial, sans-serif !important;

[class^="text-"] {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}

.text-body-1 {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}

.text-h5 {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.v-list-group {
  --prepend-width: 0;
}

.image-button {
  background: none;
  padding: 0;
  border: none;
  box-shadow: none;
}

.button-image {
  width: 50px; /* Set your desired width */
  height: auto;
}

.image-container {
  /* Ensuring the div does not limit the image size */
  // display: flex;
  align-items: center;
}

@import "assets/css/v-phone-input.css";
</style>
