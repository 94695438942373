<script setup>
import InitLoading from "@/components/InitLoading.vue";
import TransactionCard from "@/components/TransactionCard.vue";
import axiosInstance from "@/lib/axios";
import { useQuery } from "@tanstack/vue-query";
import { onMounted } from "vue";

async function getTransactions() {
  try {
    return (await axiosInstance.get("/payments/transactions")).data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.log(error);
    } else {
      throw error;
    }
  }
}

const {
  isPending,
  isError,
  data: transactionsData,
  error,
  refetch,
} = useQuery({
  queryKey: ["getTransactions"],
  queryFn: getTransactions,
});

onMounted(async () => {
  // skip
});
</script>

<template>
  <div>
    <v-container>
      <p class="text-h6 text-center font-weight-bold">Transacciones</p>
    </v-container>

    <v-container
      v-if="transactionsData && transactionsData.length == 0"
      style="height: 200px"
    >
      <v-row align-content="center" class="fill-height" justify="center">
        <v-col class="text-body-12 text-center" cols="12">
          No tienes ninguna transacción registrada
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="transactionsData" fluid>
      <v-row v-for="transaction in transactionsData" :key="transaction.id">
        <TransactionCard
          :id="transaction.id"
          :creationDate="transaction.created_at"
          :amount="transaction.amount"
          :status="transaction.status"
          :isConfirmedByProvider="transaction.is_confirmed_by_provider"
          :paymentMethodType="transaction.payment_method_type"
          :billPayments="transaction.bill_payments"
        />
      </v-row>
    </v-container>

    <v-container v-else-if="isError" style="height: 400px">
      {{ error }}
    </v-container>

    <InitLoading :show="isPending" />
  </div>
</template>
