<script setup>
import { formatAmount, formatDate } from "@/lib/utils";
import { computed, defineProps, ref, toRefs } from "vue";
const props = defineProps([
  "id",
  "creationDate",
  "amount",
  "status",
  "isConfirmedByProvider",
  "paymentMethodType",
  "billPayments",
]);
const {
  id,
  creationDate,
  amount,
  status,
  isConfirmedByProvider,
  paymentMethodType,
  billPayments,
} = toRefs(props);

const isShowBills = ref(false);

const statusComputed = computed(() => {
  if (isConfirmedByProvider.value) {
    return { class: "success", text: "Pagada" };
  } else {
    return { class: "warning", text: "Pago AloPay" };
  }
});
</script>

<template>
  <v-container>
    <v-card class="text-left" elevation="2">
      <v-card-text class="pb-0">
        <v-container>
          <v-row class="no-gutters">
            <v-col class="text-center" cols="4">
              <span class="font-weight-bold">Fecha</span>
              <p>{{ formatDate(creationDate) }}</p>
            </v-col>
            <v-col class="text-center">
              <span class="font-weight-bold">Monto</span>
              <p>
                {{ formatAmount(amount) }}
              </p>
            </v-col>
            <v-col class="text-center">
              <span class="font-weight-bold">Método</span>
              <p>
                {{ paymentMethodType }}
              </p>
            </v-col>
            <v-col class="text-center">
              <v-chip :color="statusComputed.class">
                <span class="text-none font-weight-bold">{{
                  statusComputed.text
                }}</span></v-chip
              >
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col class="mc-2">
              <v-btn
                target="_blank"
                variant="outlined"
                color="primary"
                class="text-none"
                @click="isShowBills = true"
                >Ver facturas pagadas</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>

  <v-dialog v-model="isShowBills" max-width="400" persistent>
    <v-card>
      <template v-slot:prepend
        ><v-icon icon="mdi-information" color="primary"></v-icon>
      </template>

      <v-card-text>
        <v-table>
          <thead>
            <tr class="text-subtitle-1 font-weight-bold">
              <th class="text-center">Proveedor</th>
              <th class="text-center">Contrato</th>
              <th class="text-center">Monto</th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="{ bill: item } in billPayments" :key="item.name">
              <td class="text-center">{{ item.source_provider_name }}</td>
              <td class="text-center">{{ item.contract_number }}</td>
              <td class="text-center">
                {{ formatAmount(item.amount) }}
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text>

      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn @click="isShowBills = false">
          <span class="text-none" @click="handleEditReset">OK</span>
        </v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>
