<script setup>
import BillCard from "@/components/BillCard.vue";
import InitLoading from "@/components/InitLoading.vue";
import axiosInstance from "@/lib/axios";
import { useQuery } from "@tanstack/vue-query";
import { onMounted } from "vue";

async function getPendingBills() {
  try {
    return (await axiosInstance.get("/contracts/pending_bills")).data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.log(error);
    } else {
      throw error;
    }
  }
}

const {
  isPending,
  isError,
  data: pendingBillsData,
  error,
} = useQuery({
  queryKey: ["getPendingBills"],
  queryFn: getPendingBills,
});

onMounted(() => {
  // skip
});
</script>

<template>
  <div>
    <v-container>
      <v-row class="justify-center">
        <v-col>
          <v-btn
            class="text-none"
            color="primary"
            variant="outlined"
            :to="{ name: 'contracts-create' }"
            block
          >
            Registrar un contrato
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <p class="text-h6 text-center font-weight-bold">Disponible para pago</p>
    </v-container>

    <v-container
      v-if="pendingBillsData && pendingBillsData.length == 0"
      style="height: 200px"
    >
      <v-row align-content="center" class="fill-height" justify="center">
        <v-col class="text-body-12 text-center" cols="12">
          No tienes ningun registro pendiente de pago
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="pendingBillsData">
      <v-row
        class="mb-4"
        v-for="bill in pendingBillsData"
        :key="bill.contract_number"
      >
        <BillCard
          :id="bill.id"
          :billCoupons="bill.bill_coupons"
          :contractNumber="bill.contract_number"
          :documentNumber="bill.document_number"
          :isDue="bill.is_due"
          :isPaid="bill.is_paid"
          :isPaidInPlatform="bill.is_paid_in_platform"
          :sourceImgUrl="bill.contract.source_provider.img_url"
          :sourceName="bill.contract.source_provider.name"
          :sourceIdentifier="bill.contract.source_provider.code"
          :isBillPdfSupported="bill.is_bill_pdf_supported"
          :billPdfSearchAttr="bill.bill_pdf_search_attr"
          :expirationDate="bill.expiration_date"
          :amount="bill.amount"
          :contractDescription="bill.contract.description"
        /> </v-row
    ></v-container>

    <v-container v-else-if="isError" style="height: 400px">
      {{ error }}
    </v-container>

    <InitLoading :show="isPending" />
  </div>
</template>
