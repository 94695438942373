import DefaultLayout from "@/layouts/DefaultLayout.vue";
import LoginLayout from "@/layouts/LoginLayout.vue";
import PublicLayout from "@/layouts/PublicLayout.vue";
import axiosInstance from "@/lib/axios";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import CartChoosePaymentMethod from "@/views/cart/CartChoosePaymentMethod.vue";
import CartMethodConfirm from "@/views/cart/CartMethodConfirm.vue";
import CartStart from "@/views/cart/CartStart.vue";
import CheckoutCompletedPendingConfirmation from "@/views/checkout/CheckoutCompletedPendingConfirmation.vue";
import CheckoutPseInputStep from "@/views/checkout/CheckoutPseInputStep.vue";
import CheckoutStart from "@/views/checkout/CheckoutStart.vue";
import ContractsCreateView from "@/views/contracts/ContractsCreateView.vue";
import ContractsListView from "@/views/contracts/ContractsListView.vue";
import FintechCreatePaymentLink from "@/views/fintech/FintechCreatePaymentLink.vue";
import FintechHomeView from "@/views/fintech/FintechHomeView.vue";
import FintechIntegrationsView from "@/views/fintech/FintechIntegrationsView.vue";
import FintechPayinsList from "@/views/fintech/FintechPayinsList.vue";
import PaymentMethodsCreateView from "@/views/payment_methods/PaymentMethodsCreateView.vue";
import PaymentMethodsListView from "@/views/payment_methods/PaymentMethodsListView.vue";
import PaymentMethodTrxConfirmation from "@/views/payments/PaymentMethodTrxConfirmation.vue";
import PseTrxRedirect from "@/views/payments/PseTrxRedirect.vue";
import TransactionsListView from "@/views/payments/TransactionsListView.vue";
import ThirdpartiesCreateView from "@/views/thirdparties/ThirdpartiesCreateView.vue";
import ThirdpartiesListView from "@/views/thirdparties/ThirdpartiesListView.vue";
import VirtualAccountsDetailsView from "@/views/virtual_accounts/VirtualAccountsDetailsView.vue";
import { AxiosError } from "axios";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    component: LoginLayout,
    children: [
      {
        path: "",
        name: "login",

        component: LoginView,
      },
    ],
  },
  {
    path: "/register",
    component: LoginLayout,
    children: [
      {
        path: "",
        name: "register",
        component: RegisterView,
      },
    ],
  },
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "bills",
        name: "bills",
        component: HomeView,
      },
      {
        path: "contracts",
        name: "contracts-list",
        component: ContractsListView,
      },
      {
        path: "contracts/new",
        name: "contracts-create",
        component: ContractsCreateView,
      },
      {
        path: "paymentmethods",
        name: "paymentmethods-list",
        component: PaymentMethodsListView,
      },
      {
        path: "paymentmethods/new",
        name: "paymentmethods-create",
        component: PaymentMethodsCreateView,
      },
      {
        path: "cart/start",
        name: "cart-start",
        component: CartStart,
      },
      {
        path: "cart/choosemethod",
        name: "cart-choose-method",
        component: CartChoosePaymentMethod,
      },
      {
        path: "cart/methodconfirm",
        name: "cart-method-confirm",
        component: CartMethodConfirm,
      },
      {
        path: "payments/trxconfirmation",
        name: "payments-trx-confirmation",
        component: PaymentMethodTrxConfirmation,
      },
      {
        path: "payments/pseredirect",
        name: "payments-pse-redirect",
        component: PseTrxRedirect,
      },
      {
        path: "transactions",
        name: "transactions-list",
        component: TransactionsListView,
      },
      {
        path: "",
        name: "fintech-home",
        component: FintechHomeView,
      },
      {
        path: "fintech/payins",
        name: "fintech-payins-list",
        component: FintechPayinsList,
      },
      {
        path: "fintech/paymentlinks",
        name: "fintech-payment-link-create",
        component: FintechCreatePaymentLink,
      },
      {
        path: "fintech/integrations",
        name: "fintech-integrations",
        component: FintechIntegrationsView,
      },
      {
        path: "fintech/thirdparties",
        name: "fintech-thirdparties-list",
        component: ThirdpartiesListView,
      },
      {
        path: "fintech/thirdparties/new",
        name: "fintech-thirdparties-create",
        component: ThirdpartiesCreateView,
      },
      {
        path: "fintech/virtualaccounts/:virtualAccountId",
        name: "fintech-virtual-accounts-details",
        component: VirtualAccountsDetailsView,
      },
    ],
  },
  {
    path: "/checkout",
    component: PublicLayout,
    children: [
      {
        path: ":paymentLinkId",
        name: "checkout-start",
        component: CheckoutStart,
      },
      {
        path: ":paymentLinkId/pse/details",
        name: "checkout-pse-details",
        component: CheckoutPseInputStep,
      },
      {
        path: ":paymentLinkId/completed",
        name: "checkout-completed-pending-confirmation",
        component: CheckoutCompletedPendingConfirmation,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    if (
      to.path.includes("/checkout") ||
      to.path.includes("/login") ||
      to.path.includes("/register")
    ) {
      next();
    } else {
      const result = await axiosInstance.get("/auth/check");

      if (result?.status === 200) {
        next();
      }
    }
  } catch (e) {
    if (
      e instanceof AxiosError &&
      (!e.response || [401, 403].includes(e.response.status))
    ) {
      router.replace({ name: "login" });
    }
  }

  next();
});

export default router;
