<script setup>
import axiosInstance from "@/lib/axios";
import { useSnackbarStore } from "@/store";
import { useMutation } from "@tanstack/vue-query";
import { useField, useForm } from "vee-validate";
import { useRouter } from "vue-router";
import * as yup from "yup";

const router = useRouter();
const snackbarStore = useSnackbarStore();

const documentTypeList = [
  {
    name: "Cédula",
    code: "CC",
  },
  {
    name: "Cédula extranjería",
    code: "CE",
  },
  {
    name: "NIT",
    code: "NIT",
  },
];

const validationSchema = yup.object().shape({
  name: yup.string().required("El campo es requerido"),
  documentType: yup.string().test({
    name: "is-set",
    test(value, ctx) {
      return value ?? ctx.createError({ message: "Debe escoger una opción" });
    },
  }),
  documentNumber: yup.string().required("El campo es requerido"),
  email: yup
    .string()
    .email("Debes ingresar un email valido")
    .matches(/^(?!.*@[^,]*,)/)
    .optional(),
  phoneNumber: yup.string().optional(),
});

const { handleSubmit } = useForm({
  validationSchema,
});
const { value: nameValue, errorMessage: nameErrorMessage } = useField(
  "name",
  validationSchema
);
const { value: documentTypeValue, errorMessage: documentTypeErrorMessage } =
  useField("documentType", validationSchema);
const { value: documentNumberValue, errorMessage: documentNumberErrorMessage } =
  useField("documentNumber", validationSchema);
const { value: emailValue, errorMessage: emailErrorMessage } = useField(
  "email",
  validationSchema
);
const { value: phoneNumberValue, error: phoneNumberErrorMessage } = useField(
  "phoneNumber",
  validationSchema
);

const { isPending, mutate } = useMutation({
  mutationFn: async () =>
    await axiosInstance.post("/thirdparties", {
      name: nameValue.value,
      document_type: documentTypeValue.value,
      document_number: documentNumberValue.value,
      email: emailValue.value,
      phone_number: phoneNumberValue.value,
    }),
  onSuccess: () => {
    snackbarStore.showSnackbar("Tercero registrado con exito");
    router.replace({ name: "fintech-thirdparties-list" });
  },
  onError: (error) => {
    if (error.response.data.code) {
      switch (error.response.data.code) {
        case "thirdparty_already_exists":
          snackbarStore.showSnackbar(
            "Ya existe un tercero con los mismos datos"
          );
          break;
        default:
          snackbarStore.showSnackbar(
            "No se pudo registrar el tercero, vuelve a intentar"
          );
      }
    } else {
      snackbarStore.showSnackbar(
        "No se pudo registrar el tercero, vuelve a intentar"
      );
    }
  },
});

const onSubmit = handleSubmit(async (values) => {
  mutate(values);
});
</script>

<template>
  <v-container>
    <p class="text-h6 font-weight-bold text-center">Registro de tercero</p>
    <v-card class="pa-5 my-5" variant="elevated" :loading="isPending">
      <v-card-text class="pa-4">
        <p class="text-body-1 mb-10">Ingrese los datos del tercero:</p>
        <v-form @submit.prevent="onSubmit">
          <v-row>
            <v-text-field
              single-line
              label="Nombre"
              variant="outlined"
              clearable
              maxlength="100"
              v-model="nameValue"
              :error-messages="nameErrorMessage"
              :disabled="isPending"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-select
              :items="documentTypeList"
              item-title="name"
              item-value="code"
              label="Tipo de documento"
              variant="outlined"
              v-model="documentTypeValue"
              :error-messages="documentTypeErrorMessage"
              :disabled="isPending"
            >
              <template v-slot:item="{ props }">
                <v-list-item v-bind="props"></v-list-item>
              </template>
            </v-select>
          </v-row>
          <v-row>
            <v-text-field
              label="Número de documento"
              variant="outlined"
              clearable
              maxlength="20"
              v-model="documentNumberValue"
              :error-messages="documentNumberErrorMessage"
              :disabled="isPending"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              label="E-mail"
              variant="outlined"
              clearable
              maxlength="100"
              v-model="emailValue"
              :error-messages="emailErrorMessage"
              :disabled="isPending"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-col class="pa-0"
              ><v-phone-input
                variant="outlined"
                label="Teléfono"
                country-icon-mode="svg"
                v-model="phoneNumberValue"
                :error-messages="phoneNumberErrorMessage"
                :disabled="isPending"
              ></v-phone-input
            ></v-col>
          </v-row>
        </v-form>
        <v-row class="text-center mt-2">
          <v-col>
            <v-btn
              color="primary"
              variant="outlined"
              class="text-none"
              :disabled="isPending"
              :to="{ name: 'fintech-thirdparties-list' }"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="primary"
              variant="outlined"
              class="text-none"
              @click="onSubmit"
              :disabled="isPending"
              >Registrar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
