<script setup>
import axiosInstance from "@/lib/axios";
import { formatAmount } from "@/lib/utils";
import {
  useCartStore,
  usePaymentDetailsStore,
  useSnackbarStore,
} from "@/store";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { storeToRefs } from "pinia";
import { Form, useField, useForm } from "vee-validate";
import { onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
import * as yup from "yup";

const router = useRouter();
const snackbarStore = useSnackbarStore();
const cartStore = useCartStore();
const { cartTotal, cartItemIds } = storeToRefs(cartStore);
const paymentDetailsStore = usePaymentDetailsStore();
const isRedirecting = ref(false);

const validationSchema = yup.object().shape({
  paymentMethodTypeChoice: yup.string().required("El campo es requerido"),
  paymentMethodChoice: yup
    .string()
    .nullable()
    .optional()
    .when("paymentMethodTypeChoice", {
      is: "mymethods",
      then: () => yup.string().required("El campo es requerido"),
    }),
  fullname: yup
    .string()
    .nullable()
    .optional()
    .when("paymentMethodTypeChoice", {
      is: "pse",
      then: () => yup.string().required("El campo es requerido"),
    }),
  documentType: yup
    .string()
    .nullable()
    .optional()
    .when("paymentMethodTypeChoice", {
      is: "pse",
      then: () => yup.string().required("El campo es requerido"),
    }),
  documentNumber: yup
    .string()
    .nullable()
    .optional()
    .when("paymentMethodTypeChoice", {
      is: "pse",
      then: () =>
        yup
          .number()
          .typeError("Debe ser un número")
          .required("El campo es requerido"),
    }),
  phoneNumber: yup
    .string()
    .nullable()
    .optional()
    .when("paymentMethodTypeChoice", {
      is: "pse",
      then: () =>
        yup
          .number()
          .typeError("Debe ser un número")
          .required("El campo es requerido"),
    }),
  // email: yup
  //   .string()
  //   .nullable()
  //   .optional()
  //   .when("paymentMethodTypeChoice", {
  //     is: "pse",
  //     then: () =>
  //       yup
  //         .string()
  //         .email("Debes ingresar un email valido")
  //         .matches(/^(?!.*@[^,]*,)/)
  //         .required("El campo es requerido"),
  //   }),
  bank: yup
    .string()
    .nullable()
    .optional()
    .when("paymentMethodTypeChoice", {
      is: "pse",
      then: () => yup.string().required("El campo es requerido"),
    }),
});

const { handleSubmit, resetForm } = useForm({
  validationSchema,
});

const { value: paymentMethodTypeChoiceValue } = useField(
  "paymentMethodTypeChoice",
  validationSchema
);

const { value: paymentMethodChoiceValue } = useField(
  "paymentMethodChoice",
  validationSchema
);

const { value: documentTypeValue, errorMessage: documentTypeErrorMessage } =
  useField("documentType", validationSchema);

const { value: documentNumberValue, errorMessage: documentNumberErrorMessage } =
  useField("documentNumber", validationSchema);

const { value: fullnameValue, errorMessage: fullnameErrorMessage } = useField(
  "fullname",
  validationSchema
);

const { value: phoneNumberValue, errorMessage: phoneNumberErrorMessage } =
  useField("phoneNumber", validationSchema);

// const { value: emailValue, errorMessage: emailErrorMessage } = useField(
//   "email",
//   validationSchema
// );

const { value: bankValue, errorMessage: bankErrorMessage } = useField(
  "bank",
  validationSchema
);

async function getPaymentMethods() {
  try {
    return (await axiosInstance.get("/payment_methods")).data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.log(error);
    } else {
      throw error;
    }
  }
}

const {
  isPending,
  data: paymentMethodsData,
  refetch: refetchPaymentMethods,
} = useQuery({
  queryKey: ["getPaymentMethods"],
  queryFn: getPaymentMethods,
  disabled: true,
});

const documentTypeList = [
  {
    name: "Cédula",
    code: "CC",
  },
  {
    name: "Cedúla Extranjeria",
    code: "CE",
  },
];

const bankList = [
  {
    name: "AV Villas",
    code: "CO_AV_VILLAS",
  },
  {
    name: "Bancolombia",
    code: "CO_BANCOLOMBIA",
  },
  {
    name: "Banco de Bogotá",
    code: "CO_BOGOTA",
  },
  {
    name: "BBVA",
    code: "CO_BBVA",
  },
  {
    name: "Banco de Bogotá",
    code: "CO_BOGOTA",
  },
  {
    name: "Banco de Occidente",
    code: "CO_OCCIDENTE",
  },
  {
    name: "Banco Popular",
    code: "CO_POPULAR",
  },
  {
    name: "Daviplata",
    code: "CO_DAVIPLATA",
  },
  {
    name: "Lulo Bank",
    code: "CO_LULO",
  },
  {
    name: "Davivienda",
    code: "CO_DAVIVIEDA",
  },
  {
    name: "Movii",
    code: "CO_MOVII",
  },
  {
    name: "Nequi",
    code: "CO_NEQUI",
  },
  {
    name: "Nu",
    code: "CO_NU",
  },
  {
    name: "RappiPay",
    code: "CO_RAPPIPAY",
  },
  {
    name: "ScotiaBank",
    code: "CO_SCOTIABANK",
  },
];

const { isPending: isPendingPse, mutate: mutatePse } = useMutation({
  mutationFn: () =>
    axiosInstance.post(
      `/payments/payin`,
      {
        method_type: "PSE",
        details: {
          payer_type:
            documentTypeValue.value == "CC" ? "INDIVIDUAL" : "BUSINESS",
          payer_fullname: fullnameValue.value,
          payer_doc_number: documentNumberValue.value,
          payer_doc_type: documentTypeValue.value,
          payer_phone_number: documentNumberValue.value,
          bank_code: bankValue.value,
          amount: cartTotal.value,
        },
        bill_ids: cartItemIds.value,
      },
      { timeout: 30000 }
    ),
  onSuccess: (response) => {
    cartStore.clear();
    window.location.replace(response.data.payment_url);
  },
  onError: (error) => {
    console.error(error);
    snackbarStore.showSnackbar("No pudimos reenviarte a PSE, intenta de nuevo");
  },
});

const { isPending: isPendingPaymentMethodOtp, mutate: mutatePaymentMethodOtp } =
  useMutation({
    mutationFn: () =>
      axiosInstance.post(
        `/payment_methods/${paymentMethodChoiceValue.value}/send_otp`
      ),
    onSuccess: (data) => {
      paymentDetailsStore.setPaymentMethodId(paymentMethodChoiceValue.value);
      router.replace({ name: "cart-method-confirm" });
    },
    onError: (error) => {
      console.error(error);
      snackbarStore.showSnackbar("No pudimos enviar el OTP, intenta de nuevo");
    },
  });

const onSubmit = handleSubmit((value) => {
  if (paymentMethodTypeChoiceValue.value === "mymethods") {
    mutatePaymentMethodOtp();
  } else if (paymentMethodTypeChoiceValue.value === "pse") {
    mutatePse();
  }
});

onBeforeMount(() => {
  refetchPaymentMethods();
  paymentMethodTypeChoiceValue.value = "mymethods";
});
</script>

<template>
  <div>
    <v-container>
      <p class="text-h6 font-weight-bold text-center">
        Escoge el método de pago
      </p>

      <v-card
        class="my-5 pa-5"
        variant="elevated"
        :loading="isPendingPaymentMethodOtp || isPendingPse || isRedirecting"
      >
        <v-card-text>
          <Form @submit.prevent="onSubmit">
            <p class="text-center">
              <span class="font-weight-bold">Total a pagar:</span>
              {{ formatAmount(cartTotal) }}
            </p>
            <div class="mt-5 flex-center">
              <v-radio-group v-model="paymentMethodTypeChoiceValue" inline>
                <v-radio color="#5e63b6" value="mymethods">
                  <template v-slot:label>
                    <span class="text-body-2">Mis métodos de pago</span>
                  </template>
                </v-radio>
                <v-radio color="#5e63b6" value="pse" @click="resetForm">
                  <template v-slot:label>
                    <span class="text-body-2">PSE</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <p
              v-if="
                paymentMethodTypeChoiceValue == 'mymethods' &&
                paymentMethodsData &&
                paymentMethodsData.length == 0
              "
              class="text-center mb-4"
            >
              No tienes ningun método de pago registrado
            </p>
            <p
              v-if="
                paymentMethodTypeChoiceValue == 'mymethods' &&
                paymentMethodsData &&
                paymentMethodsData.length == 0
              "
              class="text-center mb-10"
            >
              <v-btn
                class="text-none"
                variant="outlined"
                :to="{ name: 'paymentmethods-create' }"
                >Ir a registrar método de pago</v-btn
              >
            </p>

            <v-table
              v-if="
                paymentMethodTypeChoiceValue == 'mymethods' &&
                paymentMethodsData &&
                paymentMethodsData.length > 0
              "
              class="mb-10"
            >
              <thead>
                <tr class="text-subtitle-1 font-weight-bold">
                  <th class="text-center">Tipo</th>
                  <th class="text-center">Número</th>
                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in paymentMethodsData" :key="item.name">
                  <td class="text-center">{{ item.method_type }}</td>
                  <td class="text-center">
                    {{ item.nequi_token.phone_number }}
                  </td>
                  <td>
                    <v-radio-group
                      v-model="paymentMethodChoiceValue"
                      hide-details
                    >
                      <v-radio
                        color="#5e63b6"
                        :value="item.id"
                        class="mt-1"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
              </tbody>
            </v-table>

            <div v-if="paymentMethodTypeChoiceValue === 'pse'">
              <v-row>
                <v-text-field
                  variant="outlined"
                  clearable
                  label="Nombre completo"
                  maxlength="100"
                  v-model="fullnameValue"
                  :error-messages="fullnameErrorMessage"
                  :disabled="isPendingPse || isPendingPaymentMethodOtp"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-select
                  :items="documentTypeList"
                  item-title="name"
                  item-value="code"
                  label="Tipo de documento"
                  variant="outlined"
                  v-model="documentTypeValue"
                  :error-messages="documentTypeErrorMessage"
                  :disabled="isPendingPse || isPendingPaymentMethodOtp"
                >
                  <template v-slot:item="{ props }">
                    <v-list-item v-bind="props"></v-list-item>
                  </template>
                </v-select>
              </v-row>
              <v-row>
                <v-text-field
                  variant="outlined"
                  clearable
                  label="Número de documento"
                  maxlength="10"
                  v-model="documentNumberValue"
                  :error-messages="documentNumberErrorMessage"
                  :disabled="isPendingPse || isPendingPaymentMethodOtp"
                ></v-text-field>
              </v-row>
              <!-- <v-row>
                <v-text-field
                  variant="outlined"
                  clearable
                  label="E-mail"
                  maxlength="10"
                  v-model="emailValue"
                  :error-messages="emailErrorMessage"
                  :disabled="isPending"
                ></v-text-field>
              </v-row> -->
              <v-row>
                <v-text-field
                  variant="outlined"
                  clearable
                  label="Número de teléfono"
                  maxlength="10"
                  v-model="phoneNumberValue"
                  :error-messages="phoneNumberErrorMessage"
                  :disabled="isPendingPse"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-select
                  :items="bankList"
                  item-title="name"
                  item-value="code"
                  label="Banco"
                  variant="outlined"
                  v-model="bankValue"
                  :error-messages="bankErrorMessage"
                  :disabled="isPendingPse"
                >
                  <template v-slot:item="{ props }">
                    <v-list-item v-bind="props"></v-list-item>
                  </template>
                </v-select>
              </v-row>
            </div>

            <input
              type="hidden"
              name="paymentMethodTypeChoice"
              class="form-control"
              :value="paymentMethodTypeChoiceValue"
            />
            <v-row class="text-center">
              <v-col>
                <v-btn
                  variant="outlined"
                  color="primary"
                  class="text-none"
                  :to="{ name: 'cart-start' }"
                  :disabled="isPendingPse"
                  >Ir al carrito</v-btn
                >
              </v-col>
              <v-col>
                <v-btn
                  variant="outlined"
                  color="primary"
                  class="text-none"
                  @click="onSubmit"
                  :disabled="isPendingPse"
                  >Continuar</v-btn
                >
              </v-col>
            </v-row>
            <input
              type="hidden"
              name="paymentMethodTypeChoice"
              class="form-control"
              :value="paymentMethodTypeChoiceValue"
            />
          </Form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>

  <v-dialog v-model="isRedirecting" max-width="400" persistent>
    <v-card :loading="isRedirecting">
      <template v-slot:prepend
        ><v-icon icon="mdi-information" color="primary"></v-icon>
      </template>

      <v-card-text>
        <p class="text-none text-center mb-5">
          Te estamos redireccionando a PSE
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
