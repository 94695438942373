<script setup></script>

<template>
  <div>
    <v-container>
      <p class="text-h6 font-weight-bold text-center">
        Estamos confirmando tu pago
      </p>

      <v-card
        class="my-5"
        variant="elevated"
        :loading="isPending || isPendingPayment"
      >
        <v-card-text>
          <p class="text-center mb-10">
            En cuanto lo confirmemos el cobro veras tu factura como pagada en
            AloPay.
          </p>
          <v-alert
            text="Tus pagos por AloPay estaran reflejados en tu proveedor de servicio al final de la mañana del siguiente día hábil."
            type="info"
            color="deep-purple-accent-4"
            variant="tonal"
            class="mb-10"
          ></v-alert>
          <v-row class="text-center">
            <v-col>
              <v-btn
                variant="outlined"
                color="primary"
                class="text-none"
                :to="{ name: 'bills' }"
                >Ir a inicio</v-btn
              >
            </v-col></v-row
          >
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
