<script setup>
import axiosInstance from "@/lib/axios";
import { useSnackbarStore } from "@/store";
import { useMutation } from "@tanstack/vue-query";
import { useField, useForm } from "vee-validate";
import { ref } from "vue";
import { useRouter } from "vue-router";
import * as yup from "yup";

import emblem from "@/assets/logo-emblem.svg";
import logoname from "@/assets/logo-name.svg";

const step = ref(1);
const newUserId = ref("");
const router = useRouter();
const snackbarStore = useSnackbarStore();

const validationSchema = yup.object().shape({
  name: yup.string().required("El campo es requerido"),
  email: yup
    .string()
    .email("Debes ingresar un email valido")
    .matches(/^(?!.*@[^,]*,)/)
    .required("El campo es requerido"),
  password: yup
    .string()
    .min(8, "Debe tener mínimo 8 caracteres")
    .required("El campo es requerido"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "La contraseña debe ser la misma"),
});

const { handleSubmit } = useForm({
  validationSchema,
});

const email = useField("email", validationSchema);
const name = useField("name", validationSchema);
const password = useField("password", validationSchema);
const confirmPassword = useField("confirmPassword", validationSchema);

const { isPending, mutate } = useMutation({
  mutationFn: ({ email, name, password, confirmPassword }) =>
    axiosInstance.post("/auth/register", {
      email: email,
      name: name,
      password: password,
      confirm_password: confirmPassword,
    }),
  onSuccess: (response) => {
    step.value = 2;
    newUserId.value = response.data.user_id;
  },
  onError: (error) => {
    switch (error.response.data.code) {
      case "user_email_already_exists":
        snackbarStore.showSnackbar("El usuario ya esta registrado");
        break;
      case "email_confirmation_in_progress":
        snackbarStore.showSnackbar(
          "Tienes un proceso de verificación de correo en progreso"
        );
        break;
      default:
        snackbarStore.showSnackbar(
          "No pudimos registrarte. Intentalo de nuevo"
        );
    }
  },
});

const onSubmit = handleSubmit(async (values) => {
  mutate(values);
});

// verification code

const verificationCodeSchema = yup.object().shape({
  verificationCode: yup
    .number()
    .typeError("Debe ser un número")
    .required("El campo es requerido"),
});

const { handleSubmit: handleCodeSubmit } = useForm({
  validationSchema: verificationCodeSchema,
});

const verificationCode = useField("verificationCode", verificationCodeSchema);

const { isPending: isPendingCode, mutate: mutateCode } = useMutation({
  mutationFn: ({ verificationCode }) =>
    axiosInstance.post("/auth/verify_email", {
      user_id: newUserId.value,
      verification_code: verificationCode,
    }),
  onSuccess: () => {
    snackbarStore.showSnackbar(
      "Correo confirmado, te contactaremos para activar tu cuenta"
    );
    router.replace({ name: "login" });
  },
  onError: (error) => {
    switch (error.response.data.code) {
      case "incorrect_email_verification_code":
        snackbarStore.showSnackbar("Código incorrecto");
        break;
      default:
        snackbarStore.showSnackbar(
          "No pudimos verificar el código. Intentalo de nuevo"
        );
    }
  },
});

const onCodeSubmit = handleCodeSubmit(async (values) => {
  mutateCode(values);
});
</script>

<template>
  <v-container class="fill-height justify-center">
    <v-card
      class="px-10 mx-10"
      variant="elevated"
      width="500"
      :loading="isPending"
      :hidden="step != 1"
    >
      <v-card-text class="pa-4">
        <v-container class="d-flex justify-center align-center ma-0">
          <v-sheet width="70">
            <v-img :src="emblem"></v-img>
          </v-sheet>
          <v-sheet width="100">
            <v-img :src="logoname"></v-img>
          </v-sheet>
        </v-container>
        <v-form @submit.prevent="onSubmit">
          <v-row>
            <v-text-field
              label="E-mail"
              variant="outlined"
              clearable
              v-model="email.value.value"
              :error-messages="email.errorMessage.value"
              :disabled="isPending"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              label="Nombre Completo"
              variant="outlined"
              clearable
              v-model="name.value.value"
              :error-messages="name.errorMessage.value"
              :disabled="isPending"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              label="Contraseña"
              type="password"
              variant="outlined"
              clearable
              v-model="password.value.value"
              :error-messages="password.errorMessage.value"
              :disabled="isPending"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              label="Confirmar Contraseña"
              type="password"
              variant="outlined"
              clearable
              v-model="confirmPassword.value.value"
              :error-messages="confirmPassword.errorMessage.value"
              :disabled="isPending"
            ></v-text-field>
          </v-row>
        </v-form>

        <v-container class="d-flex justify-center align-center ma-0 ga-3">
          <v-sheet width="70">
            <v-btn
              color="primary"
              type="button"
              variant="plain"
              @click.prevent="router.replace('/login')"
            >
              Cancelar
            </v-btn>
          </v-sheet>
          <v-sheet width="100">
            <router-link to="/register" replace>
              <v-btn color="primary" variant="plain" @click="onSubmit"
                >Registrar</v-btn
              >
            </router-link>
          </v-sheet>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card
      class="pa-10 my-10"
      variant="elevated"
      width="500"
      :loading="isPendingCode"
      :hidden="step != 2"
    >
      <v-card-title class="headline">
        <span class="font-weight-bold">AloPay</span></v-card-title
      >

      <v-card-text class="pa-4">
        <v-form @submit.prevent="onCodeSubmit">
          <v-row>
            <p>
              Hemos enviado un código a tu correo, ingresalo para terminar la
              verificación:
            </p>
            <v-text-field
              label="Código de verificación"
              maxlength="6"
              v-model="verificationCode.value.value"
              :error-messages="verificationCode.errorMessage.value"
              :disabled="isPendingCode"
              class="mt-5"
            ></v-text-field>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="onCodeSubmit">Verificar</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
