<script setup>
import { Line as LineChartGenerator } from "vue-chartjs";

import axiosInstance from "@/lib/axios";
import { formatAmount } from "@/lib/utils";
import { useQuery } from "@tanstack/vue-query";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

const data = {
  labels: ["1", "2", "3", "4", "5", "6", "7"],
  datasets: [
    {
      label: "# Cobros últimos 7 días",
      backgroundColor: "#5e63b6",
      data: [40, 39, 10, 40, 39, 80, 40],
    },
  ],
};

async function getBalance() {
  try {
    return (await axiosInstance.get("/balance")).data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.log(error);
    } else {
      throw error;
    }
  }
}

const {
  isPending,
  isError,
  data: balanceData,
  refetch,
  error,
} = useQuery({
  queryKey: ["getBalance"],
  queryFn: getBalance,
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
};
</script>

<template>
  <div>
    <v-container>
      <v-container>
        <p class="text-h6 text-center font-weight-bold">Bienvenido a AloPay</p>
      </v-container>

      <v-container class="mb-5">
        <v-row class="justify-center">
          <!-- <v-col>
            <v-btn
              class="text-none"
              color="primary"
              variant="outlined"
              :to="{ name: 'contracts-create' }"
              block
            >
              Solicitar desembolso
            </v-btn>
          </v-col> -->
          <v-col>
            <v-btn
              class="text-none"
              color="primary"
              variant="outlined"
              :to="{ name: 'fintech-payment-link-create' }"
              block
            >
              Crear un link de pago
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <p class="text-h6 font-weight-bold text-center">Balance</p>
              <p class="text-h6 text-center">
                {{ balanceData ? formatAmount(balanceData.amount) : "..." }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- <v-col>
          <v-card>
            <v-card-text>
              <p class="text-h6 font-weight-bold text-center">
                Recaudo mes actual
              </p>
              <p class="text-h6 text-center">$ 1.030.500</p>
            </v-card-text>
          </v-card>
        </v-col> -->
      </v-row>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <LineChartGenerator
                :options="options.chartOptions"
                :data="data"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <!-- <v-col>
          <v-card>
            <v-card-text>
              <LineChartGenerator
                :options="options.chartOptions"
                :data="data"
              />
            </v-card-text>
          </v-card>
        </v-col> -->
      </v-row>
    </v-container>

    <InitLoading :show="isPending" />
  </div>
</template>
