<script setup>
import { useSnackbarStore } from "@/store";
import { storeToRefs } from "pinia";

const snackbarStore = useSnackbarStore();
const { show: isShowSnackbar, message: snackbarMessage } =
  storeToRefs(snackbarStore);
</script>

<template>
  <v-app>
    <router-view></router-view>

    <v-snackbar v-model="isShowSnackbar" color="primary">
      <span class="text-none font-weight-bold">{{ snackbarMessage }}</span>

      <template v-slot:actions>
        <v-btn
          color="white"
          variant="text"
          :timeout="1000"
          @click="snackbarStore.hideSnackbar()"
        >
          <span class="text-none font-weight-bold">OK</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
