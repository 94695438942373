<script setup>
import axiosInstance from "@/lib/axios";
import { formatAmount } from "@/lib/utils";
import { useSnackbarStore } from "@/store";
import { useQuery } from "@tanstack/vue-query";
import { onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";

import emblem from "@/assets/logo-emblem.svg";
import logoname from "@/assets/logo-name.svg";

const route = useRoute();
const snackbarStore = useSnackbarStore();

const { mobile } = useDisplay();

const paymentLinkId = route.params.paymentLinkId;
const isValidLink = ref(true);

async function getPaymentLinkInfo() {
  try {
    return (await axiosInstance.get(`/payment_links/${paymentLinkId}/info`))
      .data;
  } catch (error) {
    isValidLink.value = false;
    if (error.response && error.response.status === 400) {
      console.log(error);
      snackbarStore.showSnackbar(
        "No pudimos obtener la información de tu link de pago, vuelve a intentarlo"
      );
    } else {
      throw error;
    }
  }
}

const {
  isPending,
  isError,
  data: paymentLinkData,
  refetch,
  error,
} = useQuery({
  queryKey: ["getPaymentLinkInfo"],
  queryFn: getPaymentLinkInfo,
  disabled: true,
});

onBeforeMount(async () => {
  await refetch();
});
</script>

<template>
  <v-container
    class="d-flex flex-column justify-center align-center"
    style="height: 100vh"
  >
    <v-container class="d-flex justify-center align-center mb-0">
      <v-sheet width="70">
        <v-img :src="emblem"></v-img>
      </v-sheet>
      <v-sheet width="100">
        <v-img :src="logoname"></v-img>
      </v-sheet>
    </v-container>
    <v-card
      class="pa-5 mx-5"
      variant="elevated"
      :loading="isPending"
      :style="mobile ? 'width: 90vw' : 'width: 60vw'"
    >
      <v-card-text v-if="!paymentLinkId || !isValidLink" class="pa-4">
        <p class="text-center">No pudimos encontrar tu payment link</p>
      </v-card-text>

      <v-card-text
        v-if="
          paymentLinkId &&
          isValidLink &&
          paymentLinkData &&
          paymentLinkData.status != 'PENDING'
        "
        class="pa-4"
      >
        <p class="text-center">Este link de pago no puede ser utilizado</p>
      </v-card-text>

      <v-card-text
        v-if="
          paymentLinkId &&
          isValidLink &&
          paymentLinkData &&
          paymentLinkData.status == 'PENDING'
        "
        class="pa-4"
      >
        <v-row class="mb-10">
          <v-col class="text-center">
            <span class="font-weight-bold text-body-1">Cobro por</span>
            <p>{{ paymentLinkData.requester.name }}</p>
          </v-col>
          <v-col class="text-center">
            <span class="font-weight-bold text-body-1">Monto</span>
            <p>{{ formatAmount(paymentLinkData.amount) }}</p>
          </v-col>
          <v-col class="text-center">
            <span class="font-weight-bold text-body-1">Referencia</span>
            <p>{{ paymentLinkData.reference }}</p>
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <p class="text-none font-weight-bold text-body-1">
            Escoge tu método de pago:
          </p>
        </v-row>

        <v-row class="mb-5 justify-center">
          <v-btn
            variant="flat"
            :to="{
              name: 'checkout-pse-details',
              params: { paymentLinkId: paymentLinkId },
            }"
          >
            <v-img
              class="button-image"
              aspect-ratio="16/9"
              cover
              src="https://seeklogo.com/images/P/pse-logo-B9F0EF77DD-seeklogo.com.png"
            ></v-img>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
