<script setup>
import { toRefs, defineProps, defineEmits, ref } from "vue";
import { useLoaderStore, useSnackbarStore } from "@/store";
import axiosInstance from "@/lib/axios";
import { useMutation } from "@tanstack/vue-query";

const props = defineProps([
  "id",
  "accountNumber",
  "methodType",
  "methodImgUrl",
]);
const { id: paymentMethodId, accountNumber, methodType } = toRefs(props);

const loaderStore = useLoaderStore();
const snackbarStore = useSnackbarStore();
const isShowConfirmationRemoval = ref(false);
const emit = defineEmits(["refreshPaymentMethodList"]);

// Remove mutation
const { mutate: removeMutate } = useMutation({
  mutationFn: async () => {
    loaderStore.showLoader();
    await axiosInstance.delete(`/payment_methods/${paymentMethodId.value}`);
  },
  onError: (error) => {
    snackbarStore.showSnackbar(
      "No pudimos remover tu método de pago, vuelve a intentar"
    );
  },
  onSuccess: () => {
    snackbarStore.showSnackbar("Método de pago removido");
    emit("refreshPaymentMethodList");
  },
  onSettled: () => {
    isShowConfirmationRemoval.value = false;
    loaderStore.hideLoader();
  },
});
</script>

<template>
  <v-container align="center">
    <v-card elevation="2">
      <v-card-text class="headline">
        <v-row no-gutters>
          <v-col>
            <v-img :width="30" :src="methodImgUrl"></v-img>
          </v-col>
          <v-col>
            <span class="font-weight-bold">Tipo</span>
            <p>{{ methodType }}</p></v-col
          >
          <v-col>
            <span class="font-weight-bold">Número</span>
            <p>{{ accountNumber }}</p></v-col
          >
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-row>
              <v-col class="mc-2">
                <v-btn
                  :href="billFileUrl"
                  target="_blank"
                  variant="outlined"
                  color="warning"
                  class="text-none"
                  @click="isShowConfirmationRemoval = true"
                  >Remover
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>

  <v-dialog v-model="isShowConfirmationRemoval" max-width="400" persistent>
    <v-card>
      <template v-slot:prepend
        ><v-icon icon="mdi-information" color="primary"></v-icon>
      </template>

      <v-card-text>
        <p class="text-none">Esta seguro de remover el método de pago?</p>
      </v-card-text>

      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn @click="isShowConfirmationRemoval = false">
          <span class="text-none">Cancelar</span>
        </v-btn>

        <v-btn @click="removeMutate">
          <span class="text-none">Remover</span>
        </v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>
