<script setup>
import { useLoaderStore, useSnackbarStore } from "@/store";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";

const router = useRouter();

const loaderStore = useLoaderStore();
const { show: isShowLoader, message: loaderMessage } = storeToRefs(loaderStore);

const snackbarStore = useSnackbarStore();
const { show: isShowSnackbar, message: snackbarMessage } =
  storeToRefs(snackbarStore);

const { mobile } = useDisplay();
</script>

<template>
  <v-app>
    <router-view></router-view>

    <v-dialog v-model="isShowLoader" max-width="400">
      <v-card>
        <v-card-text class="text-md-center">
          <span class="text-none">{{ loaderMessage }}</span>
        </v-card-text>
        <v-progress-linear
          color="primary"
          height="6"
          indeterminate
          rounded
        ></v-progress-linear>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="isShowSnackbar" color="primary">
      <span class="text-none font-weight-bold">{{ snackbarMessage }}</span>

      <template v-slot:actions>
        <v-btn
          color="white"
          variant="text"
          :timeout="1000"
          @click="snackbarStore.hideSnackbar()"
        >
          <span class="text-none font-weight-bold">OK</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>
