<script setup>
import { formatAmount } from "@/lib/utils";
import { useCartStore } from "@/store";
import { storeToRefs } from "pinia";
import { computed, defineProps, toRefs } from "vue";
const props = defineProps([
  "id",
  "billCoupons",
  "contractNumber",
  "documentNumber",
  "isDue",
  "isPaid",
  "isPaidInPlatform",
  "isBillPdfSupported",
  "billPdfSearchAttr",
  "sourceImgUrl",
  "sourceName",
  "sourceIdentifier",
  "expirationDate",
  "amount",
  "contractDescription",
]);
const {
  id,
  billCoupons,
  contractNumber,
  documentNumber,
  isDue,
  isPaid,
  isPaidInPlatform,
  isBillPdfSupported,
  billPdfSearchAttr,
  sourceImgUrl,
  sourceName,
  sourceIdentifier,
  expirationDate,
  amount,
  contractDescription,
} = toRefs(props);

const billFileUrl = computed(() => {
  if (isBillPdfSupported) {
    return `${process.env.VUE_APP_BASE_URL ?? "http://localhost:8000"}/bills/${
      id.value
    }/pdf`;
  }
  return "";
});

const status = computed(() => {
  if (isPaidInPlatform.value) {
    return { class: "warning", text: "Pago AloPay" };
  } else if (isDue.value) {
    return { class: "error", text: "Vencida" };
  } else if (isPaid.value) {
    return { class: "success", text: "Pagada" };
  } else {
    return { class: "primary", text: "A tiempo" };
  }
});

const cartStore = useCartStore();
const { isItemInCart } = storeToRefs(cartStore);
</script>

<template>
  <v-container>
    <v-card class="text-left" elevation="2">
      <v-card-title class="headline">
        <v-row>
          <v-col>
            <v-img :width="60" :src="sourceImgUrl"></v-img>
          </v-col>
          <v-col class="d-flex ga-3 flex-row-reverse align-center">
            <div class="text-right">
              <v-chip :color="status.class">
                <span class="text-none font-weight-bold">{{
                  status.text
                }}</span></v-chip
              >
            </div>
            <div class="text-center">
              <span class="font-weight-bold text-subtitle-2">Contrato </span>
              <span class="text-subtitle-2">{{ contractNumber }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-container>
          <v-row class="no-gutters">
            <v-col class="text-center">
              <span class="font-weight-bold">Descripción</span>
              <p>{{ contractDescription }}</p>
            </v-col>
            <v-col class="text-center">
              <span class="font-weight-bold">Expiración</span>
              <p>{{ expirationDate }}</p>
            </v-col>
            <v-col class="text-center">
              <span class="font-weight-bold">Monto</span>
              <p>
                {{ formatAmount(amount) }}
              </p>
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col class="mc-2">
              <v-btn
                :href="billFileUrl"
                target="_blank"
                variant="outlined"
                color="primary"
                class="text-none"
                :disabled="!isBillPdfSupported"
                >Factura</v-btn
              >
            </v-col>
            <v-col class="mc-2" v-if="!isItemInCart(id) && !isPaidInPlatform">
              <v-btn
                target="_blank"
                variant="outlined"
                color="primary"
                class="text-none"
                :disabled="billCoupons.length == 0"
                @click="
                  cartStore.addItem({
                    id,
                    amount,
                    contractNumber,
                    billerName: sourceName,
                  })
                "
                >Adicionar a carrito</v-btn
              >
            </v-col>
            <v-col class="mc-2" v-if="isItemInCart(id) && !isPaidInPlatform">
              <v-btn
                target="_blank"
                variant="outlined"
                color="primary"
                class="text-none"
                :disabled="billCoupons.length == 0"
                @click="cartStore.removeItem(id)"
                >Remover de carrito</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
